import React, { createContext, useState, useContext, useEffect } from 'react';

// ایجاد context برای مدیریت تم
export const ThemeContext = createContext();

// پرووایدر برای ارسال state تم به کامپوننت‌ها
export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // تابع برای تغییر وضعیت تم
  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.setAttribute('data-theme', 'dark');
    } else {
      document.body.setAttribute('data-theme', 'light');
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// استفاده از context
export const useTheme = () => useContext(ThemeContext);
