import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import { useTheme } from '../ThemeContext/ThemeContext'; // وارد کردن hook برای دسترسی به تم
import './MarqueeDemo.css';
import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/khiar.png";
import p7 from "../../assets/black berry.jpg";
import p8 from "../../assets/cofee.png";
import p9 from "../../assets/choco.png";
import p10 from "../../assets/vanil.png";
import p11 from "../../assets/coco.png";
import p12 from "../../assets/stra.png";
import p13 from "../../assets/shah.png";
import p14 from "../../assets/but.png";
import p15 from "../../assets/mango.png";
import p16 from "../../assets/fan.png";
import p17 from "../../assets/shir3.png";

// لیست تمام تصاویر
const allImages = [
  p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17
];

// لیست محصولات با جزئیات هر محصول
const reviews = [
  { id: 1, name: "پرتقال", username: "@Orange", body: "اسانس پرتقال خوراکی برای انواع کیک و ویفر و بیسکویت، آبمیوه", img: p1 },
  { id: 2, name: "هندوانه", username: "@Watermelon", body :"اسانس هندوانه خوراکی برای انواع محصولات لبنی، آبمیوه و آبنیات", img: p2 },
  { id: 3, name: "آلبالو", username: "@Sourcherry", body: "اسانس آلبالو خوراکی برای انواع آبمویه، فیلر کیک و بیسکویت ، تافی و آبنبات", img: p3 },
  { id: 4, name: "طالبی", username: "@Muskmelon", body: "اسانس طالبی خوراکی برای انواع آبمیوه، ویفر و تافی و آبنبات", img: p4 },
  { id: 5, name: "آناناس", username: "@Pineapple", body: "اسانس طالبی خوراکی برای انواع بستنی، آبنبات، و تافی", img: p5 },
  { id: 6, name: "وانیل", username: "@Vanilla", body: "اسانس وانیل خوراکی برای انواع کیک و شیرینی و بیسکویت، انواع بستنی", img: p10 },
  { id: 7, name: "شاتوت", username: "@Mulberry", body: "اسانس شاتوت خوراکی برای انواع محصولات لبنی، تافی و آبنبات و آبمیوه", img: p13 },
  { id: 8, name: "نارگیل", username: "@Coconut", body: "اسانس نارگیل خوراکی برای انواع کیک و بیسکویت و تافی", img: p11 },
  { id: 9, name: "توت فرنگی", username: "@Strawberry", body: "اسانس توت فرنگی خوراکی برای انواع کیک و ویفر و بیسکویت، محصولات لبنی، انواع تافی و آبنبات", img: p12 }, 
  { id: 10, name: "قهوه", username: "@Coffee", body: "اسانس قهوه خوراکی برای انواع بیسکویت و تافی، انواع پودرهای قهوه فوری", img: p8 }, 
  { id: 11, name: "شکلات", username: "@Chocolate", body: "اسانس شکلات خوراکی برای انواع کیک و کلوچه و ویفر، تافی و شکلات، و انواع محصولات لبنی", img: p9 }, 
  { id: 12, name: "انبه", username: "@Mango", body: "اسانس انبه خوراکی برای آبمیوه، انواع بستنی و تافی و آبنبات", img: p15 }, 
  { id: 13, name: "فندق", username: "@Hazelnut", body: "اسانس فندق خوراکی برای انواع شکلات ، کیک و کلوچه و بیسکویت", img: p16 }, 
  { id: 14, name: "شیرکندانسه", username: "@CondensedMilk", body: "اسانس شیرکندانسه خوراکی برای انواع کیک و کلوچه و بیسکویت، انواع محصولات لبنی و تافی", img: p17 }, 
  { id: 16, name: "کره", username: "@Butter", body: "اسانس کره خوراکی برای انواع کیک و بیسکویت، انواع کره گیاهی و پنیر پیتزا، انواع تافی", img: p14 },
];

// کامپوننت کارت نمایش هر محصول
const ReviewCard = ({ img, name, username, body }) => {
  return (
    <div className="review-card">
      <div className="review-header">
        <img className="review-avatar" src={img} alt={name} />
        <div className="review-info">
          <figcaption className="review-name">{name}</figcaption>
          <p className="review-username">{username}</p>
        </div>
      </div>
      <blockquote className="review-body">{body}</blockquote>
    </div>
  );
};

// کامپوننت اصلی MarqueeDemo
export function MarqueeDemo() {
  const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }

    // انتخاب تصادفی از تصاویر
    const shuffledImages = [...allImages].sort(() => Math.random() - 0.5);
    const selectedReviews = shuffledImages.slice(0, Math.min(80, allImages.length)); 
    setVisibleReviews(
      selectedReviews.map((img) => {
        return reviews.find(review => review.img === img);
      })
    );
  }, [isDarkMode]);

  useEffect(() => {
    // بارگذاری مجدد تصاویر هر 5 ثانیه
    const interval = setInterval(() => {
      const shuffledImages = [...allImages].sort(() => Math.random() - 0.5);
      const selectedReviews = shuffledImages.slice(0, Math.min(80, allImages.length));
      setVisibleReviews(
        selectedReviews.map((img) => {
          return reviews.find(review => review.img === img);
        })
      );
    }, 7000);

    return () => clearInterval(interval); // پاک کردن interval در هنگام unmount
  }, []);

  return (
    <div className="marquee-demo">
      <h2 className="product-title">محصولات ما</h2>
      <div className="marquee">
        {visibleReviews.map((review) => (
          review ? <ReviewCard key={review.id} {...review} /> : null
        ))}
      </div>
      <Link to="/mahsulat" className="show-more-button">نمایش همه محصولات</Link>
    </div>
  );
}
