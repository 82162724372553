import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import iranFlag from "../../assets/iran.png";
import './English.css';
import Slider from '../Slider/Slider';
import Select from 'react-select';
import y2 from "../../assets/logo1.png"
import cato from "../../assets/encot.pdf"
import { useTheme } from '../ThemeContext/ThemeContext';

const productOptions = [
    { value: 'Product 1', label: 'Product 1' },
    { value: 'Product 2', label: 'Product 2' },
    { value: 'Product 3', label: 'Product 3' },
    { value: 'Product 4', label: 'Product 4' },
    { value: 'Product 5', label: 'Product 5' },
    { value: 'Product 6', label: 'Product 6' },
    { value: 'Product 7', label: 'Product 7' },
    { value: 'Product 8', label: 'Product 8' },
    { value: 'Product 9', label: 'Product 9' },
    { value: 'Product 10', label: 'Product 10' },
    { value: 'Product 11', label: 'Product 11' },
    { value: 'Product 12', label: 'Product 12' },
    { value: 'Product 13', label: 'Product 13' },
    { value: 'Product 14', label: 'Product 14' },
    { value: 'Product 15', label: 'Product 15' },
    { value: 'Product 16', label: 'Product 16' },
    { value: 'Product 17', label: 'Product 17' },
    { value: 'Product 18', label: 'Product 18' },
    { value: 'Product 19', label: 'Product 19' },
    { value: 'Product 20', label: 'Product 20' },
];

export function English  () {
    const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]); 

    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        city: '',
        factoryName: '',
        phoneNumber: '',
        request: '',
        isFirstTime: false,
        selectedProducts: []
    });
    const [result, setResult] = useState('');

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            isFirstTime: e.target.checked,
        });
    };

    const handleProductChange = (selectedOptions) => {
        setFormData(prevState => ({
            ...prevState,
            selectedProducts: selectedOptions ? selectedOptions.map(option => option.value) : []
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResult('Sending...');

        const formDataToSend = new FormData();
        formDataToSend.append('access_key', '336f06bc-5f57-4332-bddd-1cb824b5199d');
        formDataToSend.append('firstName', formData.firstName);
        formDataToSend.append('lastName', formData.lastName);
        formDataToSend.append('companyName', formData.companyName);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('factoryName', formData.factoryName);
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('request', formData.request);
        formDataToSend.append('isFirstTime', formData.isFirstTime ? 'Yes' : 'No');
        formDataToSend.append('selectedProducts', formData.selectedProducts.join(', '));

        const response = await fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            body: formDataToSend,
        });

        const data = await response.json();

        if (data.success) {
            setResult('Form submitted successfully! Our team will contact you soon.');
            setFormData({
                firstName: '',
                lastName: '',
                companyName: '',
                city: '',
                factoryName: '',
                phoneNumber: '',
                request: '',
                isFirstTime: false,
                selectedProducts: []
            });
        } else {
            setResult('An error occurred: ' + data.message);
        }
    };

    return (
        <div>
        <div>
            <Slider/>
             <p className="real-taste-text" >   Experience the real taste with us</p>
              <div className='text-container'>
            <h2>About Us</h2>
           
                          



	<p>                                                   


Kimiyagar Aryan Essence Company began its operations under the name Hajizadeh Trading in 1989, as the exclusive representative of Dragoco Germany, focusing on the import of food, cosmetic, and hygienic flavors. After 10 years of continuous effort and leadership in this industry, with a deep understanding of the Iranian market and the increasing demand for a diverse range of flavors, the company embarked on a project to establish the first flavor production factory (under the license of Symrise Germany). This included the development of a "Central Laboratory" aligned with the latest global technology. This goal, achieved in less than two years with the help of German experts, led to the launch of the factory’s first phase in Abhar city, and the official start of Kimiyagar Aryan Essence Company.
<br></br>
From 2002 Onwards:
The company started localizing knowledge by employing top graduates from Iran’s leading universities under the supervision of German specialists. In 2010, coinciding with its tenth anniversary, and relying on 20 years of experience and expertise, the company inaugurated the second phase of its factory in Eshtehard Industrial Park. This expansion aimed to streamline processes and introduced new machinery. The company also established a new production line for emulsions used in carbonated soft drinks.
<br></br>
Present Day:
Today, Kimiyagar Aryan Essence, with its proprietary formulations, cutting-edge expertise, and a team of skilled professionals and well-equipped laboratories, is producing high-quality products that meet global standards. The company holds a respected and competitive position among food industry manufacturers.




            </p>
           
          </div>
       
            <nav className="navbar">
              <div className="logo"><img src={y2} alt="" /></div>
                <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
                    <li className="nav-link" onClick={closeMenu}><Link to="/">Home</Link></li>
                    {/* <li className="nav-link" onClick={closeMenu}><Link to="/services">Services</Link></li>
                    <li className="nav-link" onClick={closeMenu}><Link to="/products">Products</Link></li> */}
                    <li className="nav-link" onClick={closeMenu}><Link to="/encot"></Link>
                   
         
          <a
            href={cato}  
            download="catalog.pdf"   
            className="download-button"
          >
           Download Catalog
          </a></li>
                    {/* <li className="nav-link" onClick={closeMenu}><Link to="/representatives">Representatives</Link></li> */}
                    <li className="nav-link" onClick={closeMenu}><Link to="/contact" className="contact-button">Contact Us</Link></li>
                </ul>
                <div className="lang-container">
                    <img src={iranFlag} alt="Iran Flag" className="flag-icon" />
                    <Link to="/">
                        <button className="lang-button">Farsi</button>
                    </Link>
                </div>
            </nav>

            <div className="container2">
                <div className="form-section">
                    <h2>Sample Request Form</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="isFirstTime">Is this your first time contacting us?</label>
                            <input
                                type="checkbox"
                                id="isFirstTime"
                                name="isFirstTime"
                                checked={formData.isFirstTime}
                                onChange={handleCheckboxChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="companyName">Company Name</label>
                            <input
                                type="text"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="factoryName">Factory Name</label>
                            <input
                                type="text"
                                id="factoryName"
                                name="factoryName"
                                value={formData.factoryName}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="selectedProducts">Select Products (You can choose multiple)</label>
                            <Select
                                id="selectedProducts"
                                isMulti
                                options={productOptions}
                                onChange={handleProductChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="request">How did you get to know us?</label>
                            <textarea
                                id="request"
                                name="request"
                                value={formData.request}
                                onChange={handleChange}
                            />
                        </div>

                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                    {result && <p>{result}</p>}
                </div>
                 <div className="google-map">
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d404.8086583032554!2d51.433193745276824!3d35.73926909057423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e03fe62c4bcf9%3A0xac14a3c6d276c235!2sAryan%20essance%20office!5e0!3m2!1sen!2s!4v1712057808219!5m2!1sen!2s"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>

        </div>
        
    );
};

export default English;
