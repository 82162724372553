import React ,{useState,useEffect} from 'react';
import './Footer.css'; 
import { useTheme } from '../ThemeContext/ThemeContext';

 export function SocialMediaSection  () {
  
 const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]); 

  return (
    <div className="container">
      <h3 className="title">تماس با ما</h3>

      <div className="address">

        <p>آدرس: تهران خیابان خرمشهر خیابان نیلوفر کوچه11 پلاک27            
           <lord-icon

    src="https://cdn.lordicon.com/tdtlrbly.json"
   trigger="loop"
    delay="2000"
    stroke="light">
   
</lord-icon>
        </p>
        <p>تلفن:-021- 88518331      
            <lord-icon
    src="https://cdn.lordicon.com/lpgbnoeo.json"
    trigger="loop"
    delay="2000"
    stroke="light">
   
</lord-icon>
        </p>
      </div>

      <div className="social-icons">
        {/* LinkedIn */}
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <lord-icon
    src="https://cdn.lordicon.com/mgampcwp.json"
   trigger="loop"
    delay="2000"
    stroke="light">
  
</lord-icon>
        </a>

        {/* Facebook */}
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <lord-icon
    src="https://cdn.lordicon.com/mpigogwa.json"
    trigger="loop"
    delay="2000"
    stroke="light">
   
</lord-icon>
        </a>

        {/* Instagram */}
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
         <lord-icon
    src="https://cdn.lordicon.com/bmlkvhui.json"
    trigger="loop"
    delay="2000"
    stroke="light"></lord-icon>
    
        </a>

        {/* WhatsApp */}
        <a href="https://wa.me/+989127937569" target="_blank" rel="noopener noreferrer">
        <lord-icon
    src="https://cdn.lordicon.com/pvwuzuvc.json"
   trigger="loop"
    delay="2000"
    stroke="light">
    
</lord-icon>
        </a>
      </div>
      <h3> desing by mi.mo group</h3>
    </div>
   
  );
};

export default SocialMediaSection;
