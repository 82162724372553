import React, { useState, useContext } from 'react';
import { ThemeContext } from '../ThemeContext/ThemeContext'; // اطمینان حاصل کنید که مسیر درست است
import './About.css';

const About = () => {
  const [expanded, setExpanded] = useState(false);
  const { isDarkMode } = useContext(ThemeContext); // دریافت وضعیت تم از context

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`about ${isDarkMode ? 'dark-mode' : 'light-mode'}`}> {/* اعمال تم بر اساس وضعیت Context */}
      <div className={`about-container ${expanded ? 'expanded' : ''}`}>
        <div className='about-right'>
          <div className='text-container'>
            <h2>درباره ما</h2>
            <p className={`text-content ${expanded ? 'expanded' : ''}`}>
              <h3>معرفی</h3>
              شرکت صنایع کیمیاگر آریان اسانس یکی از تخصصی‌ترین شرکت‌های کشور در حوزه تولید اسانس و بنیان نوشابه‌های گازدار و امولسیون‌های میوه‌ای با استفاده از هزاران ماده اولیه، عصاره‌ها در منطقه صنعتی اشتهارد در زمینی به وسعت 10 هزار متر مربع راه‌اندازی و عملیات تولیدی محصولات شرکت با طراحی صفر تا صد توسط متخصصین داخلی، نیاز مشتریان خود را مرتفع می‌سازد.
              <h3>(1368)</h3>
              بازرگانی حاجی زاده واردات اسانس های خوراکی ، آرایشی و بهداشتی نماینده انحصاری شرکت داگوکو
              <h3>(1378)</h3>
              پروژه ساخت اولین کارخانه تولید اسانس تحت لیسانس شرکت سیمرایز آلمان
              <h3>(1379)</h3>
              تاسیس آزمایشگاه مرکزی مطابق با دانش روز
              <h3>(1389)</h3>
              جهت تسهیل در فرآیند نسبت به بومی سازی دانش تولید اسانس و طراحی طعم با استفاده از ماشین آلات جدیدتر و هزار نوع ماده اولیه در کارخانه واقع در شهرک صنعتی اشتهارد
              <h3>(1390)</h3>
              ایجاد خط تولید جهت امولسیون های نوشابه های گازدار
              در حال حاضر شرکت صنایع کیمیاگر آریان اسانس، محصولاتی با کیفیت قابل رقابت در حد استاندارهای جهانی را تولید و به مشتریان عرضه می‌نماید.
            </p>
            <button className='read-more' onClick={handleToggle}>
              {expanded ? 'بستن' : 'بیشتر بخوانید'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
