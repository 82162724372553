import React ,{useEffect,useState}from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/but.png";
import p7 from "../../assets/shir3.png";

import p8 from "../../assets/cofee.png";
import p9 from "../../assets/choco.png";
import p10 from "../../assets/vanil.png";
import p11 from "../../assets/coco.png";
import p12 from "../../assets/stra.png";
import p13 from "../../assets/shah.png";
import p14 from "../../assets/but.png";
import p15 from "../../assets/mango.png";
import p16 from "../../assets/fan.png";
import p17 from "../../assets/shir3.png";
import p18 from "../../assets/greentea.png";
import p19 from "../../assets/alovera.png";
import p20 from "../../assets/olive.png";
import p21 from "../../assets/benanamilk.png";
import p22 from "../../assets/lemon.png";

import { useTheme } from '../ThemeContext/ThemeContext';
import './Mahulat.css';

const products = [
    { id: 1, img: p1, title: 'اسانس پرتقال', description: 'اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوهبر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.',metaDescription:"اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواعنوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد." ,
        body:(
            <div class="description-box">
                <h3>معرفی</h3>
                <p>اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوه
بر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.
اسانس پرتقال بر اساس نوع روغن استفاده شده دارای طعم های و پروفایل های مختلفی است. این پروفایل ها شامل اسانس پرتقال
والنسیا، پرتقال تلخ و پوستی، پرتقال گوشتی و پرتقال شیرین می باشد.
اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواع
نوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد.</p>
                <h3>محصولات شیرینی و آردی</h3>
                <p>اسانس استفاده شده در این محصولات معمولا دارای مقاومت بالایی دارد. از نظر پروفایلی نیز پروفایل والنسیا و شیرین برای این
محصول مناسب است. اما هر چه محصول نهایی دارای آب کمتری باشد مقاومت اسانس به کار رفته باید بیشتر باشد. بطور مثال
اسانس پرتقال در بیکوییت به مراتب مقاومت بیشتری نسبت به اسانس استفاده شده در کیک و کلوچه دارد. البته از نظر فنی مانعی
برای استفاده مشترک در همه محصولات وجود ندارد. اما توجه به چنین نکاتی قیمت تمام شده را کاهش می دهد.</p>
                <h3>محصولات تافی و آبنبات</h3>
                <p>با توجه به نوع محصولات استفاده از نوع اسانس پرتقال متفاوت است. برای محصولاتی با پایه روغنی استفاده از اسانس روغنه
پیشنهاد می شود. اما برای اسانس هایی با پایه آب همیشه نیاز به اسانس هایی با حلالیت مناسب در آب نیاز دارید.</p>
            </div>
        )},
    { id: 2, img: p2, title: 'اسانس هندوانه', description: "اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجه زیادی نیز داردر" , metaDescription:"اسانس هندوانه از میوه‌ی خوشمزه و آبدار هندوانه استخراج می‌شود و دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این اسانس",
        body:(
            <div class="description-box">
                <h2>اسانس هندوانه خوراکی: طعم و عطر تابستان در هر قطره</h2>
                <h3>مقدمه</h3>
                <p>اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجه
قرار گرفته است. این اسانس نه تنها طعمی شگفت‌انگیز به غذاها و نوشیدنی‌ها می‌بخشد، بلکه خواص سلامتی زیادی نیز دارد. در
این مقاله، به بررسی خواص، کاربردها و مزایای اسانس هندوانه می‌پردازیم.</p>
                <h3>خواص اسانس هندوانه</h3>
                <p>اسانس هندوانه از میوه‌ی خوشمزه و آبدار هندوانه استخراج می‌شود و دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این اسانس
غنی از ویتامین C و ویتامین A بوده و می‌تواند به تقویت سیستم ایمنی بدن کمک کند. همچنین، هندوانه حاوی مقدار زیادی آب است
که به هیدراته نگه داشتن بدن کمک می‌کند.</p>
<h3>کاربردهای اسانس هندوانه</h3>
<p>اسانس هندوانه در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها کاربرد دارد. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیه
می‌شوند، می‌توان به اسموتی‌ها، آبمیوه‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس به
عنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها استفاده می‌شود.</p>
<h3>مزایای اسانس هندوانه</h3>
<p>
1. طعم و عطر مطبوع: اسانس هندوانه عطر و طعمی شبیه به میوه‌ی تازه آن دارد و می‌تواند حس و حال تابستان را به هر
غذایی اضافه کند.
2. خواص سلامتی: مصرف اسانس هندوانه می‌تواند به بهبود هیدراتاسیون و سلامت پوست کمک کند. همچنین،
آنتی‌اکسیدان‌های موجود در آن به مبارزه با رادیکال‌های آزاد کمک می‌کنند.
3. تنوع در استفاده: این اسانس به راحتی می‌تواند در انواع غذاها و نوشیدنی‌ها گنجانده شود، از سالادها گرفته تا دسرها و
اسموتی‌ها.</p>
<h3>نتیجه‌گیری</h3>
<p>اسانس هندوانه خوراکی یک گزینه‌ی عالی برای کسانی است که به دنبال افزودن طعمی تازه و خوشمزه به غذاها و نوشیدنی‌های
خود هستند. با خواص متعدد سلامتی و عطر و طعم دلپذیر، این اسانس می‌تواند به یک انتخاب محبوب در آشپزخانه تبدیل شود. اگر
به دنبال یک تجربه‌ی جدید در طعم‌دهی به غذاهای خود هستید، اسانس هندوانه</p>
            </div>
        )
    },
    { id: 3, img: p3, title: 'اسانس البالو ', description: "آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود،خریداسانس البالو  " , metaDescription:"اسانس آلبالو در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها استفاده می‌شود. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیهمی‌شوند، می‌توان به آبمیوه‌ها، اسموتی‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس بهعنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها مورد استفاده قرار می‌گیرد.",
        body:(
            <div class="description-box">

                <h2>اسانس آلبالو خوراکی: طعمی دلپذیر و خواص بی‌نظیر</h2>
                <h3>مقدمه</h3>
                <p>اسانس آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود، مورد
توجه بسیاری قرار گرفته است. این اسانس نه تنها به غذاها و دسرها طعمی شگفت‌انگیز می‌بخشد، بلکه خواص سلامتی بسیاری نیز
دارد. در این مقاله، به بررسی ویژگی‌ها، کاربردها و مزایای اسانس آلبالو می‌پردازیم.</p>
<h3>خواص اسانس آلبالو</h3>
<p>اسانس آلبالو از میوه‌ی خوشمزه و آبدار آلبالو استخراج می‌شود و سرشار از ویتامین‌ها و آنتی‌اکسیدان‌هاست. این اسانس حاوی
ویتامین C و ترکیبات ضد التهابی است که می‌تواند به تقویت سیستم ایمنی بدن و کاهش التهابات کمک کند. همچنین، آلبالو به عنوان
یک منبع خوب فیبر شناخته می‌شود که می‌تواند به بهبود عملکرد گوارش کمک کند.</p>
<h2>کاربردهای اسانس آلبالو</h2>
<p>اسانس آلبالو در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها استفاده می‌شود. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیه
می‌شوند، می‌توان به آبمیوه‌ها، اسموتی‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس به
عنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها مورد استفاده قرار می‌گیرد.</p>
<h2>مزایای اسانس آلبالو</h2>
<p>1. عطر و طعم منحصر به فرد: اسانس آلبالو عطر و طعمی مشابه میوه‌ی تازه آلبالو دارد و می‌تواند به راحتی حس و حال
تازگی را به هر غذایی اضافه کند.
2. خواص سلامتی: مصرف اسانس آلبالو می‌تواند به بهبود خواب و کاهش استرس کمک کند. این اسانس به دلیل دارا بودن
ملاتونین، می‌تواند به خواب بهتر و بهبود کیفیت خواب کمک کند.
3. تنوع در استفاده: اسانس آلبالو به راحتی در انواع غذاها و نوشیدنی‌ها گنجانده می‌شود. می‌توانید آن را به سالادها،دسرها و حتی سس‌ها اضافه کنید.</p>
         <h2>نکات مهم در خرید اسانس آلبالو</h2>
         <p>هنگام خرید اسانس آلبالو، به کیفیت محصول توجه کنید. بهتر است از برندهای معتبر و شناخته‌شده خرید کنید تا از خلوص و کیفیت
اسانس اطمینان حاصل کنید. همچنین، حتماً به برچسب محصول توجه کنید و از محتوای شیمیایی آن مطلع شوید.</p>
<h2>نتیجه‌گیری</h2>
<p>اسانس آلبالو خوراکی یک انتخاب عالی برای افزودن طعمی تازه و لذت‌بخش به غذاها و نوشیدنی‌های شماست. با خواص متعددسلامتی و عطر و طعم دلپذیر، این اسانس می‌تواند به یک گزینه محبوب در آشپزخانه تبدیل شود. اگر به دنبال تجربه‌ای جدید در
طعم‌دهی به غذاهای خود هستید، اسانس آلبالو را امتحان کنید و از لذت آن بهره‌مند شوید!</p>
            </div>
        )},
   {
  id: 4,
  img: p4, // مسیر تصویر محصول، مثلا '/images/p6.jpg'
  title: 'طالبی',
  description: 'توضیحات کامل محصول طالبی',
  metaDescription: "اسانس طالبی با عطر و طعم طبیعی مناسب برای صنایع غذایی و محصولات آرایشی، افزاینده جذابیت محصولات با رایحه گرمسیری.",
  body: (
    <div class="description-box">
      <h3>معرفی محصول اسانس طالبی</h3>
      <p>اسانس «طالبی» با عطر و طعم طبیعی و خاص خود، گزینه‌ای عالی برای استفاده در صنایع غذایی و بهداشتی است. این اسانس در تولید محصولات متنوعی مانند نوشیدنی‌ها، دسرها، شیرینی‌جات و محصولات آرایشی و بهداشتی کاربرد دارد و رایحه‌ای طبیعی و گرمسیری به این محصولات اضافه می‌کند.</p>

      <h3>ویژگی‌های اسانس طالبی</h3>
      <p><strong>پروفایل طعم و عطر متمایز:</strong> اسانس طالبی با طعم شیرین و خاص خود، حس طراوت و تازگی را به محصولات اضافه کرده و تجربه‌ای خوشایند برای مصرف‌کننده فراهم می‌آورد.</p>
      <p><strong>پایداری حرارتی:</strong> این اسانس در برابر دما مقاوم بوده و در فرآیندهای حرارتی مانند پخت و پز یا تهیه نوشیدنی‌های گرم، کیفیت و رایحه‌ی خود را حفظ می‌کند.</p>
      <p><strong>کاربردهای گسترده:</strong> اسانس طالبی در محصولات غذایی مانند نوشیدنی‌ها، دسرها، و شیرینی‌جات و همچنین در محصولات مراقبتی و آرایشی مورد استفاده قرار می‌گیرد که به آن‌ها عطر و طعم گرمسیری و دلپذیر می‌بخشد.</p>

      <h3>مزایای استفاده از اسانس طالبی</h3>
      <p><strong>تقویت عطر و طعم طبیعی:</strong> این اسانس به‌طور طبیعی عطر و طعم طالبی را به محصولات افزوده و جذابیت آن‌ها را برای مصرف‌کنندگان بیشتر می‌کند.</p>
      <p><strong>سازگاری با محیط زیست:</strong> اسانس طالبی با رعایت استانداردهای طبیعی و ارگانیک تولید می‌شود و بدون مواد شیمیایی مضر است.</p>
      <p><strong>جذابیت برای مصرف‌کنندگان:</strong> اسانس طالبی به دلیل طعم و رایحه خوش‌بو و محبوب خود، انتخابی عالی برای تولیدکنندگانی است که به دنبال ارائه محصولات جذاب و خاص به مصرف‌کنندگان هستند.</p>

      <h3>نحوه خرید و استفاده از اسانس طالبی</h3>
      <p>برای خرید اسانس طالبی با کیفیت عالی و کسب اطلاعات بیشتر در مورد کاربردهای آن، می‌توانید با فروشگاه ما تماس بگیرید و از مشاوره کارشناسان ما بهره‌مند شوید. برای دریافت اطلاعات بیشتر، با شماره تماس ما در ارتباط باشید تا راهنمایی‌های لازم را دریافت کنید.</p>

      <p><strong>برچسب‌های مرتبط:</strong> اسانس طالبی، اسانس میوه‌ای، اسانس طبیعی، اسانس صنایع غذایی، اسانس دسر، اسانس آرایشی</p>
    </div>
  )
}
,
    {
  id: 5,
  img: p5, // مسیر تصویر محصول، مثلا '/images/p5.jpg'
  title: 'اناناس',
  description: 'توضیحات کامل محصول اناناس',
  metaDescription: "اسانس اناناس با طعم و رایحه گرمسیری، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها، و محصولات آرایشی بهداشتی.",
  body: (
    <div class="description-box">
      <h3>معرفی محصول اسانس اناناس</h3>
      <p>محصول «اناناس» یکی از انواع اسانس‌های میوه‌ای است که به دلیل رایحه و طعم خاص، کاربردهای گسترده‌ای در صنایع غذایی و بهداشتی دارد. اسانس اناناس برای تولید محصولات متنوعی مانند نوشیدنی‌ها، شیرینی‌جات، دسرها، و محصولات آرایشی بهداشتی مورد استفاده قرار می‌گیرد و به این محصولات، رایحه‌ای گرمسیری و طبیعی می‌بخشد.</p>

      <h3>ویژگی‌های اسانس اناناس</h3><strong>پروفایل طعم و عطر متمایز:</strong> اسانس اناناس با طعم شیرین و دلپذیر خود، حس تازگی و شادابی را به محصولات اضافه می‌کند و تجربه لذت‌بخشی برای مصرف‌کننده به ارمغان می‌آورد.<strong>پایداری حرارتی:</strong> این اسانس مقاوم در برابر دما است و در فرآیندهای حرارتی مانند پخت و پز و تهیه نوشیدنی‌های گرم، کیفیت خود را حفظ می‌کند.<strong>کاربرد گسترده:</strong> از اسانس اناناس در انواع محصولات غذایی، نوشیدنی‌ها، شیرینی‌ها، دسرها، و همچنین محصولات مراقبتی و آرایشی استفاده می‌شود که به آن‌ها عطر و طعم گرمسیری و طبیعی می‌دهد.

      <h3>مزایای استفاده از اسانس اناناس</h3>
      
        <strong>تقویت عطر و طعم طبیعی:</strong> این اسانس به طور طبیعی، رایحه‌ی اناناس را در محصولاتی که ممکن است عطر و طعم کمتری داشته باشند، تقویت می‌کند.
        <strong>سازگاری با محیط زیست:</strong> اسانس اناناس با توجه به استانداردهای طبیعی و ارگانیک تولید می‌شود و بدون مواد شیمیایی مضر است.
        <strong>جذابیت برای مصرف‌کنندگان:</strong> اسانس اناناس به دلیل عطر و طعم محبوبش در بین مصرف‌کنندگان بسیار مورد استقبال قرار می‌گیرد و به تولیدکنندگان کمک می‌کند تا محصولات جذاب‌تری ارائه دهند.
      

      <h3>نحوه خرید و استفاده از اسانس اناناس</h3>
      <p>برای خرید اسانس اناناس باکیفیت و دریافت مشاوره درباره کاربردهای آن، می‌توانید به فروشگاه ما مراجعه کرده و با کارشناسان ما در ارتباط باشید. همچنین، در صورت نیاز به اطلاعات بیشتر، لطفاً با شماره تماس ما تماس حاصل کنید تا راهنمایی‌های لازم را دریافت نمایید.</p>

      <p><strong>برچسب‌های مرتبط:</strong> اسانس اناناس، اسانس میوه‌ای، اسانس طبیعی، اسانس صنایع غذایی، اسانس شیرینی‌جات، اسانس آرایشی</p>
    </div>
  )
}
,
    { 
        id: 6, 
        img: p6, 
        title: "اسانس کره", 
        description: 'خرید اسانس کره با بهترین کیفیت، مناسب برای صنایع لبنی، کیک و شیرینی‌پزی.',
        metaDescription: "اسانس کره با رایحه و طعم طبیعی، مناسب برای صنایع غذایی، لبنیات، کیک و شیرینی. خرید اسانس کره با کیفیت بالا و مقاوم در برابر حرارت.",
        body: (
            <div class="description-box">
                <h2>اسانس کره</h2>
                <p>

اسانس کره از جمله اسانس های پرمصرف در صنایع غذایی می باشد. این اسانس به طور عمده در صنایع مختلف مانند لبنی، کیک و
شیرینی و انواع تافی کاربرد گسترده دارد.                </p>
                
                <h2>کاربرد در محصولات لبنی</h2>
                <p>

اسانس کره در صنایع لبنی در انواع کره، بستنی و پنیر پیتزا کاربرد دارد. البته پروفایل رایحه این اسنس در زیرمجموعه های
مختلف تفاوت زیادی دارد.
رایحه این اسانس در انواع کره گیاهی و حیوانی پروفایلی گرم و شیرین می باشد. این زایحه تداعی کننده پروفایل طبیعی این اسانس
می باشد.
اما در انواع پنیر پیتزا این پروفایل متفاوت می باشد. این پروفایل در پنیر پیتزا ترش و تیز می باشد که جایگیزینی مناسب می باشد.
در انواع بسیتنی اسانس کره بیشتر دارای پروفایل شیرین و گرم می باشد اما قدرت آن نسبت به انواع کره کمتر می باشد.                </p>
                
                <h2>کاربرد در کیک و شیرینی</h2>
                <p>

در صنعت شیرینی و کیک پروفایل این اسانس باز هم تغییر می کند. این پروفایل دارای گرمی و شیرینی بیشتری می باشد. اما تفاوت
اصلی در ساختار طراحی آن می باشد. با توجه به محصولات مختلف در این صنعت، مقاومت حرارتی نیز اهمیت پیدا می کند.
به این شکل که به ترتیب برای بیسکویت، کیک، شیرینی و کلوچه مقاومت حرارتی به نسبت کم می شود. به طور کلی هر چه
محصولات آردی نرم تر باشند یعنی آب بیشتری خواهند داشت و به همین دلیل درجه حرارت پخت آن نیز کمتر می شود.                </p>
    
                <h2>اهمیت اسانس کره در صنایع غذایی</h2>
                <p>
در دیگر بخش تولیدات اسنسن کره در تولید انواع تافی کاربرد دارد. در این بخش با توجه به بالا بودن حرارت، اسانس با مقاومت
حرارتی مطلوب است. این اسانس دارای پروفایل گرم و شیرین است.
به این ترتیب با توجه به توضیحات بالا، اسانس کره در صنعت بسیار پر کاربد است و تنوع رایحه و پروفایل بالایی دارد. که این امر
نیاز به دانش مورد یاز برای طراحی مناسب برای تمامی محصولات می باشد.
صنایع کیمیاگر آریان اسانس با به کار گیری دانش روز و بهرمندی از تجربه طعم سازان مطرح دنیا، توانسته نیاز های صنایع غذایی
تنا حدود زیادی برطرف کند. در این راه با همکاری با بزرگترین شرکت های تولید کننده مواد اولیه، در جهت بالا بردن کیفیت
محصولات توانسته جایگاه ارزشمندی در بین مشتریان ایجاد کند.                </p>
            </div>
        )
    },
    { 
        id: 7, 
        img: p7, 
        title: 'اسانس شیر کندانسه', 
        description: 'خرید اسانس شیر کندانسه با رایحه و طعم طبیعی، مناسب برای صنایع غذایی و شیرینی‌پزی.',
        metaDescription: "اسانس شیر کندانسه مناسب برای صنایع غذایی، شیرینی‌پزی و شکلات‌سازی. خرید اسانس شیر کندانسه با کیفیت بالا و رایحه دلپذیر و مقاوم در برابر حرارت.",
        body: (
            <div class="description-box">
            
                <p>

اسانس شیرکندانسه یکی از اسانس های خاص و پرمصرف در صنایع غذایی شناخته می شود. این اسانس با توجه به مصارف مختلف
و استانداردهای متفاوت، دارای تنوع زیادی در بین مشتریان می باشد.
اسانس شیرکندانسه در انواع مختلف صنایع غذایی از لبنی و شیرینی و شکلات تا انواع تافی و کلوچه ها کاربرد فراوانی دارد.
این اسانس با توجه به نوع پروفایل تولیدی، شامل اسانس شیر تازه و شیر پخته نیز می باشد.
به طور کلی اسانس های شیر دارای پروفایل شیرین و گرم می باشد و از همین روی در صنایع مختلف اسانس پرطرف دارای
محسوب می شود.                </p>
                
                <h2>کاربرد در صنایع شیرینی و شکلات</h2>
                <p>
اسانس شیرکندانسه در صنایع شیرینی و شکلات مصرف گسترده تری دارد. این اسانس به طور عمدعه در تولید کیک تی تاپ و
انواع فیلر کاربرد گسترده تری دارد.
اما با توجه به نوع حلالیت آن کاربرد بالایی در انواع شکلات و تافی نیز دارد. البته برای انواع تافی شیرپخته طعم محبوب تری
محسوب می شود و کاربرد بیشتری دارد.                </p>
                
                <h2>کاربرد در صنایع لبنی</h2>
                <p>

در صنایع لبنی، اسانس شیر کندانسه به طور عمده به عنوان طعم دهنده مکمل در انواع شیر پاستوریزه کاربرد دارد. این اسانس
شیرینی و گرمی مطبوعی بع محصول می دهد.                </p>
                
                <h2>ویژگی‌ها و مزایا</h2>
                <p>

استفاده از شیر تازه و شیر پخته در صنایع مختلف بستگی به ذائقه مصرف کننده دارد. اساسن شیر تازه از شیرینی و گرمی کمتری
برخوردار بوده و معمولا در صنایع لبنی و کیک و کلوچه مورد مصرف قرار می گیرد. اما اسانس شیر پخته دارای عطر و طعم
بیشتری بوده و معمولا در انواع تافی و شکلات مورد استفاده دارد تا باعث هر چه بیشتر شده عطر و طعم شود.
مقاومت حراراتی اسانس شیر نسبتا بالا بوده اما با استفاده از حلال های مختلف می توان این میزان از مقاومت را بسته به نوع
محصول تنظیم کرد. 
               </p>
               ,
               
            </div>
    )}
  ,  {
  id: 8,
  img: p8,
  title: 'اسانس قهوه',
  description: 'خرید اسانس قهوه با عطر و طعم قوی، مناسب برای صنایع غذایی، نوشیدنی و دسر.',
  metaDescription:
    'اسانس قهوه با رایحه دلپذیر و طبیعی، مناسب برای تولید شکلات، نوشیدنی‌ها و دسرهای متنوع. خرید اسانس قهوه با کیفیت بالا و مقاومت حرارتی عالی.',
  body: (
    <div class="description-box">
      <p>
        اسانس قهوه یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی است که با عطر و طعم طبیعی خود، انتخابی مناسب برای
        تولید انواع نوشیدنی‌ها، دسرها و محصولات پختنی می‌باشد. این اسانس قابلیت افزودن طعمی متمایز و جذاب را
        به محصولات دارد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        اسانس قهوه در تولید انواع نوشیدنی‌های گرم و سرد، مانند قهوه فوری و شیرقهوه کاربرد دارد. عطر ماندگار و
        قوی این اسانس، کیفیت محصولات را بهبود می‌بخشد و تجربه‌ای بی‌نظیر برای مصرف‌کنندگان فراهم می‌کند.
      </p>
      <h2>کاربرد در صنایع شیرینی و شکلات</h2>
      <p>
        این اسانس به‌طور گسترده‌ای در تهیه شکلات‌های تلخ و شیرین، انواع کیک، ترافل و بستنی استفاده می‌شود.
        ترکیب آن با طعم‌های دیگر مانند وانیل یا کارامل، محصولات را غنی‌تر می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس قهوه با رایحه طبیعی و مقاومت حرارتی بالا، برای انواع فرایندهای پخت مناسب است. این اسانس
        به دلیل کیفیت بالا، در محصولات متنوع غذایی و نوشیدنی استفاده می‌شود و به طور کامل با ذائقه مشتریان
        همخوانی دارد.
      </p>
    </div>
  ),
},
{
  id: 9,
  img: p9,
  title: 'اسانس شکلات',
  description: 'خرید اسانس شکلات با طعم طبیعی و ماندگار، مناسب برای صنایع غذایی و شیرینی‌پزی.',
  metaDescription:
    'اسانس شکلات با طعمی غنی و شیرین، مناسب برای تولید انواع دسرها، شکلات و کیک. خرید اسانس شکلات با کیفیت بالا و رایحه‌ای ماندگار.',
  body: (
    <div class="description-box">
      <p>
        اسانس شکلات یکی از طعم‌دهنده‌های اصلی و پراستفاده در صنایع غذایی است. این اسانس با رایحه‌ای غنی و
        دلپذیر، مناسب برای تهیه انواع دسرها، نوشیدنی‌ها و محصولات پختنی می‌باشد.
      </p>
      <h2>کاربرد در صنایع شیرینی و شکلات</h2>
      <p>
        اسانس شکلات برای تولید شکلات‌های تخته‌ای، انواع شیرینی، کیک، کوکی و ترافل استفاده می‌شود. این اسانس
        با کیفیت بالا، به محصولات طعمی ماندگار و بی‌نظیر می‌بخشد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        این اسانس در نوشیدنی‌های شکلاتی داغ، شیک‌ها و بستنی‌ها بسیار محبوب است. ترکیب آن با سایر اسانس‌ها
        مانند وانیل یا قهوه، تنوع بیشتری به محصولات اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس شکلات با مقاومت حرارتی بالا و عطر و طعم طبیعی، به‌راحتی با انواع محصولات ترکیب می‌شود.
        این اسانس انتخابی ایده‌آل برای محصولات غذایی متنوع است.
      </p>
    </div>
  ),
},
{
  id: 10,
  img: p10,
  title: 'اسانس وانیل',
  description: 'خرید اسانس وانیل با رایحه‌ای دلپذیر و شیرین، مناسب برای صنایع غذایی، آرایشی و بهداشتی.',
  metaDescription:
    'اسانس وانیل با رایحه‌ای دلپذیر و شیرین، مناسب برای تهیه انواع دسرها، کیک‌ها، محصولات آرایشی و بهداشتی. خرید اسانس وانیل با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس وانیل یکی از محبوب‌ترین اسانس‌ها در سراسر جهان است که با رایحه‌ای شیرین و دلپذیر شناخته
        می‌شود. این اسانس در صنایع غذایی، آرایشی و بهداشتی کاربرد فراوانی دارد و می‌تواند به طور طبیعی
        طعمی دلپذیر و عطر خوشایند به محصولات مختلف ببخشد.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس وانیل در تولید انواع دسرها، کیک‌ها، شیرینی‌ها و شکلات‌ها به کار می‌رود. این اسانس باعث
        طعمی بی‌نظیر و رایحه‌ای گرم و شیرین در این محصولات می‌شود که آنها را محبوب و جذاب می‌کند.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        در صنایع آرایشی و بهداشتی، اسانس وانیل به عنوان رایحه‌ای ملایم و دلپذیر برای تولید کرم‌ها،
        لوسیون‌ها، شامپوها و محصولات مراقبت از پوست استفاده می‌شود. این اسانس به دلیل خواص نرم‌کنندگی و
        تسکین‌دهندگی خود، در بسیاری از محصولات مراقبتی به کار می‌رود.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس وانیل دارای خواص ضد استرس و آرامش‌بخش است. این اسانس به کاهش اضطراب و افزایش حس راحتی
        کمک می‌کند و به همین دلیل در بسیاری از محصولات مراقبت از پوست و ماساژ به کار می‌رود.
      </p>
      <h2>خواص درمانی</h2>
      <p>
        اسانس وانیل علاوه بر رایحه خوشایند، دارای خواص درمانی نیز می‌باشد. این اسانس می‌تواند به کاهش
        دردهای عضلانی، بهبود کیفیت خواب و تسکین دردهای عصبی کمک کند. به علاوه، وانیل خواص آنتی‌اکسیدانی
        نیز دارد که به حفظ سلامت پوست و مقابله با پیری زودرس کمک می‌کند.
      </p>
    </div>
  ),
}
,

  {
  id: 11,
  img: p11,
  title: 'اسانس نارگیل',
  description: 'خرید اسانس نارگیل با رایحه‌ای گرم و طعم مطبوع، مناسب برای صنایع غذایی و آرایشی.',
  metaDescription:
    'اسانس نارگیل با عطر طبیعی و ماندگار، مناسب برای تولید دسرها، کیک‌ها، بستنی‌ها و محصولات آرایشی. خرید اسانس نارگیل با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس نارگیل یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و آرایشی است. این اسانس با رایحه‌ای گرم و طعمی
        شیرین و دلنشین، برای تولید انواع محصولات مصرفی مناسب است.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        این اسانس در تهیه انواع کیک‌ها، بستنی‌ها، تافی‌ها و نوشیدنی‌ها استفاده می‌شود. ترکیب اسانس نارگیل با
        طعم‌های دیگر مانند شکلات یا وانیل، تجربه‌ای جدید برای مصرف‌کنندگان فراهم می‌کند.
      </p>
      <h2>کاربرد در صنایع آرایشی</h2>
      <p>
        اسانس نارگیل به دلیل رایحه مطبوع خود، در تولید محصولات آرایشی و بهداشتی مانند کرم‌ها، لوسیون‌ها و
        شامپوها کاربرد گسترده‌ای دارد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس نارگیل دارای ماندگاری بالا، عطر طبیعی و مقاومت حرارتی مناسب است. این ویژگی‌ها آن را به یک
        انتخاب ایده‌آل برای صنایع مختلف تبدیل کرده است.
      </p>
    </div>
  ),
},
{
  id: 12,
  img: p12,
  title: 'اسانس توت فرنگی',
  description: 'خرید اسانس توت فرنگی با طعمی شیرین و عطر جذاب، مناسب برای دسرها و نوشیدنی‌ها.',
  metaDescription:
    'اسانس توت فرنگی با رایحه‌ای طبیعی و طعمی شیرین، مناسب برای تولید دسرها، بستنی‌ها و نوشیدنی‌ها. خرید اسانس توت فرنگی با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس توت فرنگی یکی از اسانس‌های پرطرفدار در صنایع غذایی است. این اسانس با طعمی شیرین و عطر جذاب خود،
        انتخابی مناسب برای تولید انواع محصولات دسر و نوشیدنی است.
      </p>
      <h2>کاربرد در صنایع دسر و بستنی</h2>
      <p>
        این اسانس در تولید کیک‌ها، ژله‌ها، بستنی‌ها و انواع دسرها استفاده می‌شود. عطر و طعم توت فرنگی به
        محصولات شیرینی طعمی متفاوت و تازه می‌بخشد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        اسانس توت فرنگی در تولید نوشیدنی‌های سرد و گرم، شربت‌ها و میلک‌شیک‌ها کاربرد دارد و طعمی دلنشین به
        آن‌ها اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس توت فرنگی دارای مقاومت حرارتی بالا و ماندگاری طولانی است. این اسانس به راحتی با سایر طعم‌دهنده‌ها
        ترکیب شده و محصولی با کیفیت عالی ارائه می‌دهد.
      </p>
    </div>
  ),
},
{
  id: 13,
  img: p13,
  title: 'اسانس شاه‌توت',
  description: 'خرید اسانس شاه‌توت با طعم شیرین و ترش، مناسب برای صنایع نوشیدنی و دسر.',
  metaDescription:
    'اسانس شاه‌توت با عطر طبیعی و طعمی منحصر به فرد، مناسب برای تولید دسرها، نوشیدنی‌ها و محصولات لبنی. خرید اسانس شاه‌توت با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شاه‌توت یکی از اسانس‌های جذاب در صنایع غذایی است که به دلیل طعم متمایز و عطر طبیعی خود، در
        تولید محصولات متنوع استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        این اسانس در تولید نوشیدنی‌های طبیعی و شربت‌ها استفاده می‌شود و طعمی تازه و دلچسب به محصولات اضافه
        می‌کند.
      </p>
      <h2>کاربرد در صنایع دسر و لبنی</h2>
      <p>
        اسانس شاه‌توت در تهیه دسرهای ژله‌ای، بستنی‌ها و محصولات لبنی مانند ماست میوه‌ای کاربرد دارد و طعمی
        منحصربه‌فرد به آن‌ها می‌بخشد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        این اسانس دارای رایحه‌ای طبیعی، طعمی ماندگار و مقاومت حرارتی بالا است. همچنین به راحتی با سایر
        مواد ترکیب شده و کیفیت محصول نهایی را افزایش می‌دهد.
      </p>
    </div>
  ),
},
{
  id: 14,
  img: p14,
  title: 'اسانس کره',
  description: 'خرید اسانس کره با رایحه گرم و طعمی دلنشین، مناسب برای صنایع غذایی و پخت.',
  metaDescription:
    'اسانس کره با عطر طبیعی و طعمی لذیذ، مناسب برای تولید محصولات غذایی مانند کیک‌ها، کلوچه‌ها و تافی. خرید اسانس کره با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس کره یکی از اسانس‌های پرکاربرد در صنایع غذایی است که عطر گرم و طعمی لذیذ به محصولات می‌بخشد.
        این اسانس به دلیل عطر طبیعی خود، در محصولات متنوعی مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنایع شیرینی و کیک</h2>
      <p>
        این اسانس در تولید کیک‌ها، کلوچه‌ها و تافی‌ها استفاده می‌شود. عطر و طعم کره طبیعی به محصولات، حس
        تازگی و کیفیت بالایی اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس کره با مقاومت حرارتی بالا و ماندگاری طولانی، انتخابی مناسب برای تولید محصولات پختنی است. این
        اسانس به دلیل طعم طبیعی خود، در بین مشتریان بسیار محبوب است.
      </p>
    </div>
  ),
},
{
  id: 15,
  img: p15,
  title: 'اسانس انبه',
  description: 'اسانس انبه با رایحه‌ای شیرین و طعمی گرم و استوایی، مناسب برای صنایع غذایی، نوشیدنی‌ها و محصولات آرایشی و بهداشتی است.',
  metaDescription:
    'اسانس انبه با طعمی استوایی و خوشمزه، مناسب برای محصولات آرایشی، بهداشتی و صنایع غذایی. خرید اسانس انبه با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس انبه یکی از محبوب‌ترین اسانس‌ها در صنایع مختلف است. این اسانس با رایحه‌ای گرم و شیرین،
        به‌ویژه در محصولات غذایی، نوشیدنی‌ها، و محصولات آرایشی و بهداشتی کاربرد فراوان دارد. طعم و بوی
        انبه به‌طور طبیعی یادآور فصول گرم و استوایی است و می‌تواند به محصولات طعمی دلپذیر و متفاوت
        ببخشد. اسانس انبه به دلیل خاصیت‌های طبیعی خود، در بسیاری از صنایع به‌ویژه برای تولید محصولات
        بهداشتی و آرایشی استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس انبه به دلیل خواص نرم‌کننده و مرطوب‌کننده‌ای که دارد، در بسیاری از محصولات مراقبت از پوست
        مانند کرم‌ها، لوسیون‌ها، ژل‌ها و ماسک‌های صورت استفاده می‌شود. این اسانس برای پوست‌های خشک
        مناسب است و به حفظ رطوبت پوست کمک می‌کند. رایحه طبیعی و دلپذیر انبه باعث ایجاد احساس آرامش
        و شادابی در پوست می‌شود.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس انبه در صنایع غذایی و نوشیدنی به‌ویژه در تولید دسرها، آبمیوه‌ها، بستنی‌ها و نوشیدنی‌های
        انرژی‌زا استفاده می‌شود. این اسانس طعمی استوایی و شیرین به محصولات می‌بخشد و برای کسانی که
        به دنبال طعم‌های جدید و متفاوت هستند، انتخابی عالی است. همچنین، اسانس انبه در تهیه شربت‌ها و
        کوکتل‌ها نیز کاربرد دارد.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس انبه</h2>
      <p>
        اسانس انبه علاوه بر رایحه دلپذیر خود، دارای خواص ضدالتهابی است که می‌تواند به تسکین پوست‌های
        تحریک‌شده و ملتهب کمک کند. همچنین این اسانس به دلیل وجود ویتامین‌ها و مواد مغذی، می‌تواند در
        تقویت و جوان‌سازی پوست مؤثر باشد. استفاده از اسانس انبه در محصولات مراقبت از پوست باعث نرم‌شدن
        و جوانی پوست می‌شود.
      </p>
      <h2>خواص سلامتی اسانس انبه</h2>
      <p>
        اسانس انبه همچنین دارای خواص آنتی‌اکسیدانی است که می‌تواند در کاهش آسیب‌های ناشی از رادیکال‌های
        آزاد مفید باشد. این خواص به کاهش علائم پیری زودرس و حفظ سلامت پوست کمک می‌کند. علاوه بر آن،
        اسانس انبه به‌عنوان یک عامل آرامش‌بخش و ضد استرس نیز شناخته شده است. با افزودن این اسانس به
        محصولات بهداشتی و مراقبتی، می‌توان از خواص آن برای تقویت سلامت جسم و روان بهره‌برد.
      </p>
      <h2>کاربرد در محصولات تغذیه‌ای</h2>
      <p>
        در صنعت تغذیه، اسانس انبه به‌عنوان یک ماده طبیعی برای بهبود طعم و رایحه محصولات مختلفی مانند
        کیک‌ها، شکلات‌ها، مرباها و سس‌ها استفاده می‌شود. همچنین این اسانس در تولید انواع نوشیدنی‌ها،
        از جمله اسموتی‌ها و آبمیوه‌ها، کاربرد دارد و طعمی شیرین و استوایی به این نوشیدنی‌ها می‌بخشد.
      </p>
      <h2>مزایای استفاده از اسانس انبه</h2>
      <p>
        اسانس انبه به‌عنوان یک ماده طبیعی در بسیاری از محصولات صنعتی، علاوه بر طعم و عطر دلپذیر خود،
        ویژگی‌های منحصر به‌فردی دارد که باعث محبوبیت آن در صنایع مختلف می‌شود. این اسانس باعث می‌شود که
        محصولات نه‌تنها از نظر طعم و عطر متفاوت باشند، بلکه خواص درمانی و زیبایی نیز به آن‌ها افزوده
        شود.
      </p>
    </div>
  ),
}
,
{
  id: 16,
  img: p16,
  title: 'اسانس فندق',
  description: 'خرید اسانس فندق با طعمی دلپذیر و رایحه‌ای گرم و شیرین، مناسب برای صنایع غذایی، نوشیدنی‌ها، شکلات‌ها و محصولات آرایشی.',
  metaDescription:
    'اسانس فندق با طعمی ملایم و رایحه‌ای خوشمزه، مناسب برای محصولات شکلاتی، دسرها، نوشیدنی‌ها و حتی محصولات مراقبت از پوست. خرید اسانس فندق با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس فندق با رایحه‌ای گرم و شیرین و طعمی دلپذیر یکی از اسانس‌های محبوب در صنایع مختلف است. این اسانس به
        دلیل رایحه طبیعی و خاص خود، در تهیه محصولات غذایی و بهداشتی به کار می‌رود. اسانس فندق می‌تواند به راحتی
        در تولید انواع شکلات‌ها، دسرها، نوشیدنی‌ها و همچنین در محصولات آرایشی و بهداشتی استفاده شود.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس فندق در صنایع غذایی کاربرد گسترده‌ای دارد. این اسانس به ویژه در تولید شکلات‌ها، کیک‌ها، دسرها،
        شیرینی‌ها و انواع نوشیدنی‌ها استفاده می‌شود. رایحه فندق می‌تواند به این محصولات طعمی غنی، طبیعی و
        خوشایند بدهد و آن‌ها را به تجربه‌ای دلپذیر تبدیل کند. همچنین در محصولات شیرینی‌پزی و انواع نوشیدنی‌ها
        مانند قهوه و شربت‌ها می‌توان از این اسانس برای ایجاد طعم و بویی گرم و دلچسب بهره برد.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        در صنایع آرایشی و بهداشتی، اسانس فندق به عنوان یک ماده معطر و تغذیه‌کننده به کار می‌رود. این اسانس در
        تولید کرم‌ها، لوسیون‌ها، شامپوها و سایر محصولات مراقبت از پوست و مو به علت خواص نرم‌کنندگی و آنتی‌اکسیدانی
        خود استفاده می‌شود. اسانس فندق به پوست نرمی می‌بخشد و به درمان پوست‌های خشک و آسیب‌دیده کمک می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس فندق دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این خواص به پوست کمک می‌کند تا از آسیب‌های ناشی از
        رادیکال‌های آزاد محافظت شود و روند پیری را کند کند. اسانس فندق همچنین به پوست‌های حساس کمک می‌کند تا
        نرم و مرطوب باقی بمانند. رایحه این اسانس به طور طبیعی احساس آرامش و شادی را افزایش می‌دهد و می‌تواند
        در بهبود روحیه و کاهش استرس موثر باشد.
      </p>
      <h2>خواص درمانی</h2>
      <p>
        اسانس فندق علاوه بر خواص زیبایی، دارای خواص درمانی نیز است. این اسانس می‌تواند به تسکین دردهای عضلانی
        و مفصلی کمک کند. به علاوه، استفاده از این اسانس در محصولات آرایشی به بهبود وضعیت پوست‌های آسیب‌دیده
        و ملتهب کمک می‌کند. از آنجا که اسانس فندق دارای خواص ضد التهابی است، برای کاهش التهاب و قرمزی پوست نیز
        مفید است.
      </p>
      <h2>نکات احتیاطی</h2>
      <p>
        اسانس فندق به دلیل غلظت بالا باید با دقت استفاده شود. در هنگام استفاده در محصولات آرایشی، پیشنهاد می‌شود
        که از آن در مقدار کم استفاده کنید و همیشه قبل از استفاده گسترده، تست حساسیت انجام دهید. همچنین، این
        اسانس باید دور از دسترس کودکان نگهداری شود.
      </p>
    </div>
  ),
}
,
{
  id: 17,
  img: p17,
  title: 'اسانس شیر خشک',
  description: 'خرید اسانس شیر خشک با طعمی طبیعی و عطر ملایم، مناسب برای صنایع لبنی و شیرینی‌پزی.',
  metaDescription:
    'اسانس شیر خشک با طعمی طبیعی و ملایم، مناسب برای تولید محصولات لبنی، کیک‌ها و شیرینی‌ها. خرید اسانس شیر خشک با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شیر خشک یکی از اسانس‌های پرکاربرد در صنایع غذایی است که به دلیل طعم ملایم و رایحه طبیعی خود،
        در بسیاری از محصولات استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع لبنی</h2>
      <p>
        اسانس شیر خشک در تولید محصولات لبنی مانند شیر پاستوریزه، ماست و پنیر به کار می‌رود. این اسانس
        طعمی شیرین و طبیعی به محصولات می‌بخشد.
      </p>
      <h2>کاربرد در صنایع شیرینی‌پزی</h2>
      <p>
        اسانس شیر خشک در شیرینی‌پزی به طور عمده در تولید کیک‌ها، کلوچه‌ها و تافی‌ها استفاده می‌شود. طعمی
        طبیعی و ملایم به این محصولات اضافه می‌کند.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس شیر خشک مقاومت حرارتی بالایی دارد و می‌تواند در دماهای مختلف به خوبی عملکرد داشته باشد. این
        ویژگی آن را به یک انتخاب عالی برای تولیدات پختنی تبدیل کرده است.
      </p>
    </div>
  ),
},
{
  id: 18,
  img: p18,
  title: 'اسانس چای سبز',
  description: 'اسانس چای سبز با طعمی ملایم و خاصیت ضدoxidant، مناسب برای صنایع آرایشی، بهداشتی و نوشیدنی‌ها است.',
  metaDescription:
    'اسانس چای سبز با طعمی طبیعی و خواص ضداکسیدانی، مناسب برای محصولات آرایشی، بهداشتی و نوشیدنی‌ها. خرید اسانس چای سبز با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس چای سبز از برگ‌های تازه چای سبز استخراج می‌شود و به دلیل خواص آنتی‌اکسیدانی و آرامش‌بخش خود
        در صنایع مختلف به‌ویژه در زمینه‌های آرایشی، بهداشتی و نوشیدنی‌ها به‌طور گسترده‌ای استفاده می‌شود.
        چای سبز به‌عنوان یک نوشیدنی محبوب و سالم شناخته شده است و اسانس آن ویژگی‌های مشابه چای سبز را
        در خود دارد. این اسانس برای کسانی که به دنبال ترکیبات طبیعی و فواید سلامتی هستند، گزینه‌ای
        ایده‌آل است.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس چای سبز به دلیل خواص آنتی‌اکسیدانی و ضد التهابی خود در بسیاری از محصولات آرایشی و بهداشتی
        استفاده می‌شود. این اسانس در تولید کرم‌ها، لوسیون‌ها، شوینده‌ها و ماسک‌های صورت کاربرد دارد و
        به‌عنوان یک عامل ضد پیری و ضدچروک شناخته می‌شود. خواص ضدالتهابی اسانس چای سبز به تسکین پوست
        تحریک‌شده کمک کرده و به تقویت و حفظ سلامتی پوست کمک می‌کند.
      </p>
      <h2>کاربرد در صنایع دارویی</h2>
      <p>
        اسانس چای سبز در داروسازی نیز کاربرد فراوان دارد. این اسانس به‌عنوان یک عامل ضدآنتی‌اکسیدانی
        در ترکیب با سایر داروها برای مقابله با مشکلات پوستی مانند آکنه و پیری زودرس استفاده می‌شود.
        علاوه بر این، اسانس چای سبز به‌عنوان یک عامل تقویت‌کننده سیستم ایمنی بدن شناخته شده است و برای
        درمان برخی از بیماری‌های التهابی نیز مفید است.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس چای سبز در صنایع غذایی به‌ویژه در تولید نوشیدنی‌ها، چای‌های طعم‌دار و دسرها استفاده می‌شود.
        طعم ملایم و طبیعی این اسانس باعث می‌شود که در نوشیدنی‌هایی مانند چای سبز، آبمیوه‌ها و حتی
        نوشیدنی‌های انرژی‌زا به‌کار رود. این اسانس به محصولات غذایی طعمی خاص و تازه می‌بخشد و به‌ویژه
        برای افرادی که به سلامتی خود اهمیت می‌دهند، انتخابی مناسب است.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس چای سبز</h2>
      <p>
        اسانس چای سبز حاوی ترکیبات آنتی‌اکسیدانی است که به محافظت از پوست در برابر آسیب‌های ناشی از
        رادیکال‌های آزاد کمک می‌کند. این اسانس همچنین خواص ضد پیری دارد و می‌تواند به کاهش خطوط ریز
        و چین و چروک‌ها کمک کند. علاوه بر آن، اسانس چای سبز به‌عنوان یک عامل آرامش‌بخش در کاهش استرس
        و اضطراب شناخته می‌شود و استفاده از آن در محصولات مراقبت از پوست به آرامش پوست کمک می‌کند.
      </p>
      <p>
        این اسانس همچنین به سلامت پوست کمک کرده و در کاهش التهاب‌های پوستی مانند آکنه و قرمزی موثر است.
        استفاده از اسانس چای سبز در محصولات مراقبت از پوست باعث کاهش چربی اضافی پوست و حفظ رطوبت آن
        می‌شود. این اسانس با بهبود بافت پوست، آن را نرم‌تر و لطیف‌تر می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس چای سبز در صنعت</h2>
      <p>
        اسانس چای سبز یک ترکیب طبیعی است که در مقایسه با مواد شیمیایی و مصنوعی مزایای زیادی دارد. این
        اسانس در محصولات مراقبت از پوست، نوشیدنی‌ها و حتی دسرها به‌عنوان یک ماده طبیعی و سالم
        استفاده می‌شود. همچنین، این اسانس به‌دلیل خواص آنتی‌اکسیدانی و ضدالتهابی که دارد، در بهبود
        کیفیت محصولات بهداشتی و غذایی موثر است.
      </p>
    </div>
  ),
}
,
{
  id: 19,
  img: p19,
  title: 'اسانس آلوئه‌ورا',
  description: 'اسانس آلوئه‌ورا با طعمی خنک و ملایم، مناسب برای صنایع آرایشی، بهداشتی، دارویی و محصولات خوراکی است.',
  metaDescription:
    'اسانس آلوئه‌ورا با طعمی ملایم و خنک، مناسب برای محصولات آرایشی، بهداشتی، دارویی و خوراکی. خرید اسانس آلوئه‌ورا با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس آلوئه‌ورا یکی از اسانس‌های طبیعی و پرکاربرد است که به دلیل خواص درمانی و طعمی ملایم، در صنایع
        مختلف از جمله آرایشی، بهداشتی، دارویی و خوراکی به‌طور گسترده استفاده می‌شود. آلوئه‌ورا یک گیاه با خواص
        تسکین‌دهنده و مرطوب‌کننده است که در اسانس آن این ویژگی‌ها به‌خوبی حفظ می‌شود. این اسانس برای افرادی
        که به‌دنبال ترکیبات طبیعی برای مراقبت از پوست و بهبود کیفیت زندگی خود هستند، گزینه‌ای ایده‌آل است.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس آلوئه‌ورا به دلیل خواص تسکین‌دهنده و مرطوب‌کننده خود در تولید انواع کرم‌ها، لوسیون‌ها و
        محصولات مراقبت از پوست کاربرد دارد. این اسانس به‌ویژه برای افرادی که پوست خشک یا حساس دارند،
        مفید است. علاوه بر این، اسانس آلوئه‌ورا در محصولات ضدآفتاب و ضدجوش نیز استفاده می‌شود و به
        کاهش التهاب و قرمزی پوست کمک می‌کند.
      </p>
      <h2>کاربرد در صنایع دارویی</h2>
      <p>
        اسانس آلوئه‌ورا به دلیل خواص ضدالتهابی و درمانی خود در تولید محصولات دارویی مانند پمادها و
        لوسیون‌های ترمیم‌کننده استفاده می‌شود. این اسانس در درمان سوختگی‌ها، بریدگی‌ها و زخم‌های سطحی به
        کار می‌رود و به‌عنوان یک درمان طبیعی برای تحریک روند بهبودی پوست شناخته شده است.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس آلوئه‌ورا در صنایع غذایی و نوشیدنی به‌ویژه در تولید آبمیوه‌ها، شربت‌ها و نوشیدنی‌های انرژی‌زا
        مورد استفاده قرار می‌گیرد. طعم ملایم و خنک این اسانس، آن را به یک انتخاب عالی برای طعم‌دهی به نوشیدنی‌ها
        و دسرها تبدیل کرده است. همچنین این اسانس در برخی محصولات خوراکی مانند بستنی، ماست و ژله نیز به‌کار
        می‌رود و طعمی طبیعی و دلچسب به آن‌ها می‌بخشد.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس آلوئه‌ورا</h2>
      <p>
        اسانس آلوئه‌ورا دارای خواص ضدالتهابی، مرطوب‌کننده و ترمیم‌کننده است که به سلامت پوست کمک می‌کند.
        این اسانس همچنین حاوی ترکیبات آنتی‌اکسیدانی است که از پوست در برابر آسیب‌های ناشی از رادیکال‌های
        آزاد محافظت می‌کند. استفاده از اسانس آلوئه‌ورا باعث افزایش نرمی و لطافت پوست می‌شود و از خشکی
        پوست جلوگیری می‌کند.
      </p>
      <p>
        علاوه بر آن، اسانس آلوئه‌ورا به‌عنوان یک عامل تسکین‌دهنده در برابر سوختگی‌ها، آفتاب‌سوختگی‌ها و
        التهاب‌های پوستی عمل می‌کند. این اسانس همچنین به بازسازی و تقویت بافت‌های آسیب‌دیده پوست کمک
        کرده و فرآیند بهبودی را تسریع می‌کند.
      </p>
      <h2>مزایای استفاده از اسانس آلوئه‌ورا در صنعت</h2>
      <p>
        اسانس آلوئه‌ورا به‌طور مؤثر خواص درمانی خود را در طی تولید و استفاده از محصولات مختلف حفظ می‌کند.
        این اسانس به‌عنوان یک ماده طبیعی، جایگزین مناسبی برای مواد شیمیایی و مصنوعی در محصولات آرایشی و
        بهداشتی است. علاوه بر این، این اسانس در تولید محصولات غذایی و نوشیدنی‌ها نیز به‌کار می‌رود تا
        طعمی تازه و طبیعی به آن‌ها بدهد. اسانس آلوئه‌ورا همچنین در دمنوش‌ها و نوشیدنی‌های خنک برای افرادی
        که به‌دنبال طعم‌های طبیعی هستند، مفید است.
      </p>
    </div>
  ),
}

,{
  id: 20,
  img: p20,
  title: 'اسانس زیتون',
  description: 'اسانس زیتون با عطر دلپذیر و خواص طبیعی، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و بهداشتی است.',
  metaDescription:
    'اسانس زیتون با رایحه‌ای خوشایند و خواص آنتی‌اکسیدانی، مناسب برای صنایع غذایی، بهداشتی و دارویی. خرید اسانس زیتون با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس زیتون یکی از اسانس‌های پرکاربرد در صنایع مختلف است. این اسانس با طعم و عطر منحصر به‌فرد خود، در
        صنایع غذایی، آرایشی و بهداشتی و دارویی به‌عنوان یک ترکیب طبیعی و مفید استفاده می‌شود. اسانس زیتون با
        خواص آنتی‌اکسیدانی و ضد التهابی خود، نه تنها به عنوان یک ماده افزودنی در غذاها بلکه به عنوان یک محصول
        درمانی و مراقبتی برای پوست و مو نیز به کار می‌رود.
      </p>
      <h2>کاربرد در صنایع غذایی</h2>
      <p>
        اسانس زیتون در صنایع غذایی به‌ویژه در تولید روغن‌های طعم‌دهنده، سس‌ها و غذاهای آماده کاربرد فراوانی
        دارد. این اسانس با طعمی ملایم و خوشایند، به‌طور ویژه در تهیه سس‌ها و پخت غذاهای مختلف مانند پاستا و
        سالادها استفاده می‌شود.
      </p>
      <h2>کاربرد در صنایع آرایشی و بهداشتی</h2>
      <p>
        اسانس زیتون در تولید محصولات آرایشی و بهداشتی مانند صابون‌ها، کرم‌ها و لوسیون‌ها بسیار پرکاربرد است.
        این اسانس به دلیل خواص مرطوب‌کنندگی، ضد التهابی و ترمیم‌کننده، برای مراقبت از پوست‌های حساس و خشک
        مناسب است. همچنین اسانس زیتون به تقویت و سلامت موها نیز کمک می‌کند.
      </p>
      <h2>کاربرد در داروسازی</h2>
      <p>
        اسانس زیتون در داروسازی برای تولید محصولات درمانی ضد التهاب و مسکن‌های طبیعی استفاده می‌شود. خواص
        ضد التهابی آن موجب کاهش درد و التهابات مختلف می‌شود و به همین دلیل در تهیه کرم‌ها و پمادهای
        درمانی استفاده می‌گردد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس زیتون به‌عنوان یک آنتی‌اکسیدان طبیعی، از پوست و بدن در برابر آسیب‌های محیطی محافظت می‌کند. این
        اسانس دارای خواص ضد التهابی است که می‌تواند به تسکین دردهای عضلانی و التهابات پوستی کمک کند. علاوه
        بر این، اسانس زیتون به تقویت موها، کاهش خشکی پوست و جلوگیری از پیری زودرس کمک می‌کند.
      </p>
    </div>
  ),
}
 ,{
  id: 21,
  img: p21,
  title: 'اسانس شیر موز',
  description: 'اسانس شیر موز با طعم خوشمزه و منحصر به‌فرد، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی و نوشیدنی است.',
  metaDescription:
    'اسانس شیر موز با طعم شیرین و ملایم، مناسب برای نوشیدنی‌ها، بستنی‌ها، دسرها و محصولات لبنی. خرید اسانس شیر موز با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس شیر موز یکی از اسانس‌های پرطرفدار در صنعت غذایی است که طعمی خوشمزه و طبیعی به محصولات مختلف
        می‌بخشد. این اسانس با ترکیب شیر و موز، طعمی ملایم و شیرین دارد که برای استفاده در نوشیدنی‌ها، دسرها،
        بستنی‌ها و انواع شیرینی‌ها بسیار مناسب است. اسانس شیر موز به دلیل خواص تغذیه‌ای موز و طعم شیرین و
        دلپذیرش، در بسیاری از محصولات غذایی و نوشیدنی‌ها مورد استفاده قرار می‌گیرد.
      </p>
      <h2>کاربرد در صنایع نوشیدنی</h2>
      <p>
        اسانس شیر موز به‌ویژه در صنعت تولید نوشیدنی‌ها کاربرد دارد. این اسانس به نوشیدنی‌هایی مانند اسموتی‌ها،
        شیرهای طعم‌دار، شربت‌ها و کوکتل‌ها طعمی شیرین و طبیعی می‌بخشد. با استفاده از اسانس شیر موز، می‌توان
        نوشیدنی‌های خوشمزه و مغذی تولید کرد که طعم موز و شیر در آن به‌خوبی حس شود.
      </p>
      <h2>کاربرد در صنایع دسر و بستنی</h2>
      <p>
        این اسانس در تولید دسرها و بستنی‌ها نیز کاربرد فراوانی دارد. اسانس شیر موز به‌ویژه در بستنی‌های
        طعم‌دار، کیک‌ها، پای‌ها و دیگر دسرها استفاده می‌شود و طعمی شیرین و طبیعی به این محصولات می‌بخشد.
        این اسانس برای کسانی که علاقه به طعم موز دارند، گزینه‌ای عالی است.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس شیر موز در صنایع لبنی نیز به‌ویژه در تولید شیرهای طعم‌دار، ماست‌ها و نوشیدنی‌های لبنی
        کاربرد دارد. این اسانس با ترکیب طعم شیر و موز، محصولات لبنی را به طعمی لذیذ و خوشمزه تبدیل می‌کند.
        استفاده از این اسانس می‌تواند تجربه‌ای جدید و خوشایند را برای مصرف‌کنندگان به ارمغان آورد.
      </p>
      <h2>ویژگی‌ها و مزایا</h2>
      <p>
        اسانس شیر موز نه‌تنها طعمی شیرین و دلپذیر به محصولات می‌بخشد، بلکه خواص تغذیه‌ای موز نیز در این
        اسانس نهفته است. موز منبع خوبی از پتاسیم، ویتامین B6 و ویتامین C است که به تقویت سیستم ایمنی بدن،
        بهبود عملکرد قلب و عروق و حفظ سلامت پوست کمک می‌کند. همچنین، اسانس شیر موز به‌عنوان یک طعم‌دهنده
        طبیعی، به جای استفاده از مواد شیمیایی و افزودنی‌های مصنوعی، انتخاب مناسبی برای استفاده در
        صنایع غذایی و نوشیدنی است.
      </p>
      <h2>مزایای استفاده از اسانس شیر موز در صنعت</h2>
      <p>
        استفاده از اسانس شیر موز در محصولات غذایی و نوشیدنی‌ها علاوه بر ایجاد طعم عالی، مزایای متعددی
        از جمله افزایش جذابیت و تنوع در محصولات نهایی را به همراه دارد. این اسانس می‌تواند به‌طور مؤثری
        طعم و عطر طبیعی شیر موز را در محصول نهایی حفظ کند و محصولاتی خوشمزه و طبیعی به مشتریان ارائه دهد.
      </p>
    </div>
  ),
}
  , {
  id: 22,
  img: p22,
  title: 'اسانس لیمو ترش',
  description: 'اسانس لیمو ترش با طعم تازه و ترش، مناسب برای صنایع غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی است.',
  metaDescription:
    'اسانس لیمو ترش با طعمی ترش و تازه، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها و محصولات آرایشی. خرید اسانس لیمو ترش با کیفیت بالا.',
  body: (
    <div class="description-box">
      <p>
        اسانس لیمو ترش یکی از اسانس‌های پرطرفدار در صنایع مختلف است که به دلیل طعم تازه، ترش و نشاط‌آور خود،
        کاربرد گسترده‌ای در تولید محصولات غذایی، نوشیدنی‌ها، دسرها و حتی محصولات آرایشی دارد. لیمو ترش به‌طور
        طبیعی منبع غنی از ویتامین C، آنتی‌اکسیدان‌ها و مواد مغذی است که برای سلامت پوست و بدن مفید است. اسانس
        لیمو ترش در بسیاری از محصولات، طعمی تازه و دلپذیر ایجاد می‌کند که مصرف‌کنندگان از آن لذت می‌برند.
      </p>
      <h2>کاربرد در صنایع غذایی و نوشیدنی</h2>
      <p>
        اسانس لیمو ترش در صنایع غذایی به‌ویژه در تولید نوشیدنی‌های خنک، شربت‌ها، اسموتی‌ها و کوکتل‌ها بسیار
        پرطرفدار است. این اسانس به نوشیدنی‌ها طعمی ترش و طبیعی می‌بخشد و برای تولید انواع نوشیدنی‌های تابستانی
        یا در فصل‌های گرم سال، گزینه‌ای ایده‌آل است. همچنین این اسانس در طعم‌دهی به انواع دسرها، کیک‌ها و
        شیرینی‌ها نیز به‌کار می‌رود.
      </p>
      <h2>کاربرد در محصولات لبنی</h2>
      <p>
        اسانس لیمو ترش همچنین در صنایع لبنی به‌ویژه در تولید شیرهای طعم‌دار، ماست‌ها و بستنی‌ها استفاده می‌شود.
        طعم ترش و تازه این اسانس می‌تواند به محصولات لبنی طعمی دلپذیر و متفاوت بدهد. به‌ویژه در تولید بستنی
        لیمویی یا ماست لیمو، این اسانس نقش برجسته‌ای دارد.
      </p>
      <h2>کاربرد در محصولات آرایشی و بهداشتی</h2>
      <p>
        اسانس لیمو ترش به‌عنوان یک ماده طبیعی در تولید محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس به
        دلیل خواص آنتی‌اکسیدانی و ضدباکتریایی‌اش، در کرم‌ها و لوسیون‌های صورت و بدن استفاده می‌شود و به‌طور
        مؤثری به جوانسازی پوست و جلوگیری از آسیب‌های ناشی از رادیکال‌های آزاد کمک می‌کند. همچنین این اسانس
        در محصولات ضدعفونی‌کننده مانند ژل‌های ضدعفونی کننده دست و مواد شوینده نیز به‌کار می‌رود.
      </p>
      <h2>ویژگی‌ها و مزایای اسانس لیمو ترش</h2>
      <p>
        اسانس لیمو ترش دارای خواص بی‌شماری است که علاوه بر طعم‌دهی طبیعی و خوشمزه، مزایای بسیاری برای
        سلامت بدن و پوست دارد. این اسانس غنی از ویتامین C است که به تقویت سیستم ایمنی بدن کمک کرده و
        آنتی‌اکسیدان‌های موجود در آن به کاهش التهاب و آسیب‌های ناشی از استرس اکسیداتیو کمک می‌کند.
      </p>
      <p>
        اسانس لیمو ترش همچنین دارای خواص ضدباکتریایی و ضدویروسی است که در مقابله با عفونت‌های پوستی و
        درمان مشکلات پوستی مانند آکنه موثر است. این اسانس به‌طور طبیعی می‌تواند به روشن‌تر شدن پوست کمک
        کرده و لکه‌های تیره را کاهش دهد.
      </p>
      <h2>مزایای استفاده از اسانس لیمو ترش در صنعت</h2>
      <p>
        استفاده از اسانس لیمو ترش در محصولات مختلف علاوه بر ایجاد طعم و عطر بی‌نظیر، مزایای زیادی دارد.
        این اسانس می‌تواند به‌طور مؤثری طعم ترش و تازه لیمو را در محصولات حفظ کند و به‌عنوان یک ماده طبیعی
        و سالم، جایگزین مناسبی برای طعم‌دهنده‌های مصنوعی باشد. همچنین، اسانس لیمو ترش به تولید محصولات
        با خواص درمانی و بهداشتی کمک کرده و در عین حال، تجربه‌ای لذت‌بخش برای مصرف‌کنندگان فراهم می‌کند.
      </p>
    </div>
  ),
}

];

export function Mahsulat  ()  {
    const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]); 
    const { productId } = useParams();
    const product = products.find((p) => p.id === parseInt(productId));

    if (!product) {
        return <h2>محصول یافت نشد</h2>;
    }

    return (
        <div className="product-detail">
            <Helmet>
                <title>{product.title}</title>
                <meta name="description" content={product.metaDescription || product.description} />
                <meta name="keywords" content="اسانس کره، طعم‌دهنده کره، اسانس شیر کندانسه، صنایع غذایی، خرید اسانس" />
            </Helmet>
            <img src={product.img} alt={product.title} />
            <h1>{product.title}</h1>
            <div>{product.body}</div>
        </div>
    );
};

export default Mahsulat;
