import React, { useContext } from 'react';
import { ThemeContext } from '../ThemeContext/ThemeContext'; // مسیر صحیح context خود را وارد کنید
import './Program.css';

const Program = () => {
  const { isDarkMode } = useContext(ThemeContext); // وضعیت تم را از context می‌گیریم

  return (
    <div>
      <h1 className="program-title">چرا آریان اسانس را انتخاب کنیم؟</h1>
      <div className={`program ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="programes">
          <h2>پیگیری و پشتیبانی</h2>
          <lord-icon
            className='photo'
            src="https://cdn.lordicon.com/fmjvulyw.json"
            trigger="loop"
            delay="2000"
            style={{ width: '150px', height: '150px' }}
          />
          <p className='mm'>کیفیت و ماندگاری محصولات از زمان تحویل نمونه تا تولید انبوه</p>
        </div>
        <div className="programes">
          <h2>تنوع محصولات</h2>
          <lord-icon
            className='photo'
            src="https://cdn.lordicon.com/aeeuxhmv.json"
            trigger="loop"
            delay="2000"
            stroke="light"
            style={{ width: '150px', height: '150px' }}
          />
          <p>بیش از 200 نوع اسانس و امولسیون خوراکی مختلف</p>
        </div>
        <div className="programes">
          <h2>اختصاصی سازی اسانس</h2>
          <lord-icon
            className='photo'
            src="https://cdn.lordicon.com/xdxxlakx.json"
            trigger="loop"
            delay="2000"
            stroke="light"
            style={{ width: '150px', height: '150px' }}
          />
          <p>اختصاصی سازی فرمول محصولات حسب نیاز و درخواست مشتریان</p>
        </div>
        <div className="programes">
          <h2>چرخه مستمر تولید</h2>
          <lord-icon
            className='photo'
            src="https://cdn.lordicon.com/abaxrbtq.json"
            trigger="loop"
            delay="2000"
            stroke="light"
            style={{ width: '150px', height: '150px' }}
          />
          <p>ایجاد تنوع محصولات با استفاده بیش از 1000 نوع ماده اولیه</p>
        </div>
      </div>
    </div>
  );
};

export default Program;
