import React from 'react';
import './Footer-en.css'; 

const SocialMediaSectionen = () => {
  return (
    <div className="container">
      <h3 className="title">Contact Us</h3>

      <div className="address">
        <p>Address: Iran-Tehran-Khoramshahr St.-Niloofar St.- Allay 11th-No. 27
          <lord-icon
            src="https://cdn.lordicon.com/tdtlrbly.json"
            trigger="loop"
            delay="2000"
            stroke="light">
          </lord-icon>
        </p>
        <p>Phone: +98 21 8851 8331
          <lord-icon
            src="https://cdn.lordicon.com/lpgbnoeo.json"
            trigger="loop"
            delay="2000"
            stroke="light">
          </lord-icon>
        </p>
      </div>

      <div className="social-icons">
        {/* LinkedIn */}
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <lord-icon
            src="https://cdn.lordicon.com/mgampcwp.json"
            trigger="loop"
            delay="2000"
            stroke="light">
          </lord-icon>
        </a>

        {/* Facebook */}
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <lord-icon
            src="https://cdn.lordicon.com/mpigogwa.json"
            trigger="loop"
            delay="2000"
            stroke="light">
          </lord-icon>
        </a>

        {/* Instagram */}
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <lord-icon
            src="https://cdn.lordicon.com/bmlkvhui.json"
            trigger="loop"
            delay="2000"
            stroke="light"></lord-icon>
        </a>

        {/* WhatsApp */}
        <a href="https://wa.me/123456789" target="_blank" rel="noopener noreferrer">
          <lord-icon
            src="https://cdn.lordicon.com/pvwuzuvc.json"
            trigger="loop"
            delay="2000"
            stroke="light">
          </lord-icon>
        </a>
      </div>
    </div>
  );
};

export default SocialMediaSectionen;
