import React, { useState, useEffect } from 'react';
import "./Slider.css";
import r1 from "./../../assets/Arian Esans-67.jpg";
import r2 from "./../../assets/Arian-Esans-08-1.jpg";
import r3 from "./../../assets/Arian-Esans-12.jpg";
import r4 from "./../../assets/Arian-Esans-13-1.jpg";
import r5 from "./../../assets/Arian-Esans-14.jpg";
// import r6 from "./../../assets/Arian-Esans-17.jpg";
import r7 from "./../../assets/Arian-Esans-19.jpg";
import r8 from "./../../assets/Arian-Esans-20.jpg";
import r9 from "./../../assets/Arian-Esans-24.jpg";
import r10 from "./../../assets/Arian-Esans-31.jpg";
import r11 from "./../../assets/Arian-Esans-37.jpg";

const images = [r1, r2, r3, r8, r5, r7, r4, r9, r10, r11];

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [visibleImages, setVisibleImages] = useState(images);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setVisibleImages(images.slice(0, 5));
      } else {
        setVisibleImages(images);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === null || prevIndex === visibleImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [visibleImages]);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="slider-container">
      <div className="gallery">
        {visibleImages.map((img, index) => (
          <img
            key={index}
            src={img}
            alt=""
            style={{
              flex: activeIndex === index ? 8.5 : 1,
              transition: 'all 0.3s',
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </div>
      <p className="real-taste-text" >   طعم واقعی را باما تجربه کنید</p>
    </div>
  );
};

export default Slider;
