import React ,{useState,useEffect} from 'react';
import { useTheme } from '../ThemeContext/ThemeContext';

import { Link } from 'react-router-dom';
import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/but.png";
import p8 from "../../assets/cofee.png";
import p9 from "../../assets/choco.png";
import p10 from "../../assets/vanil.png";
import p11 from "../../assets/coco.png";
import p12 from "../../assets/stra.png";
import p13 from "../../assets/shah.png";
import p14 from "../../assets/but.png";
import p15 from "../../assets/mango.png";
import p16 from "../../assets/fan.png";
import p17 from "../../assets/shir3.png";
import p18 from "../../assets/greentea.png";
import p19 from "../../assets/alovera.png";
import p20 from "../../assets/olive.png";
import p21 from "../../assets/benanamilk.png";
import p22 from "../../assets/lemon.png";


import './Products.css';


const products = [
  { 
    id: 1, 
    img: p1, 
    title: 'اسانس پرتقال', 
    description: 'اسانس پرتقال طبیعی یکی از اسانس‌های محبوب در صنایع غذایی است که برای طعم‌دهی به محصولات متنوع مانند کیک، بیسکویت، تافی، آبنبات و نوشیدنی‌ها استفاده می‌شود. این اسانس با رایحه تازه و دلپذیر خود، علاوه بر طعم دهی، خواص طبیعی پرتقال را نیز به ارمغان می‌آورد. اسانس پرتقال برای استفاده در صنایع لبنی و تنباکو نیز مناسب است. انواع مختلفی از این اسانس شامل پرتقال والنسیا، پرتقال تلخ و شیرین، هرکدام ویژگی‌های خاص خود را دارند.' 
  },
  { 
    id: 2, 
    img: p2, 
    title: 'اسانس هندوانه', 
    description: 'اسانس هندوانه یکی از محبوب‌ترین اسانس‌ها در صنایع نوشیدنی و غذایی است. با طعم و عطر طبیعی هندوانه، این اسانس در تولید نوشیدنی‌ها، دسرها و کیک‌ها به کار می‌رود. علاوه بر طعم‌دهی، اسانس هندوانه خواص ضد التهابی و آنتی‌اکسیدانی دارد که به سلامت بدن کمک می‌کند. این اسانس به‌ویژه در تولید محصولات تابستانی و خنک‌کننده بسیار محبوب است.' 
  },
  { 
    id: 3, 
    img: p3, 
    title: 'اسانس آلبالو', 
    description: 'اسانس آلبالو طبیعی با طعم ترش و شیرین خود یکی از محبوب‌ترین طعم‌دهنده‌ها در صنعت دسر و نوشیدنی است. این اسانس به‌ویژه در تولید انواع ژله‌ها، مرباها، نوشیدنی‌های گازدار و شکلات‌ها کاربرد دارد. اسانس آلبالو نه تنها طعم و عطر عالی به محصولات می‌دهد، بلکه خواص ضد میکروبی و ضد التهابی نیز دارد. این اسانس در صنعت آرایشی و بهداشتی نیز برای تولید محصولات مراقبتی پوست کاربرد دارد.' 
  },
  { 
    id: 4, 
    img: p4, 
    title: 'اسانس طالبی', 
    description: 'اسانس طالبی با رایحه ملایم و خوشبو خود، یک گزینه عالی برای استفاده در صنایع غذایی و بهداشتی است. این اسانس در تولید نوشیدنی‌ها، بستنی‌ها، کیک‌ها و انواع دسرها کاربرد دارد. اسانس طالبی با طعم دلپذیر خود، به محصولات غذایی طعم طبیعی و جذاب می‌بخشد. علاوه بر این، اسانس طالبی در صنعت آرایشی نیز برای تولید کرم‌ها و لوسیون‌ها به‌کار می‌رود.' 
  },
  { 
    id: 5, 
    img: p5, 
    title: 'اسانس اناناس', 
    description: 'اسانس اناناس طبیعی به دلیل طعم گرمسیری و منحصر به‌فرد خود، در صنایع غذایی، به ویژه در تولید نوشیدنی‌ها و دسرها، بسیار پرکاربرد است. این اسانس علاوه بر استفاده در کیک‌ها و شیرینی‌جات، در تولید محصولات آرایشی بهداشتی نیز به کار می‌رود. اسانس اناناس دارای خواص آنتی‌اکسیدانی است که به تقویت سیستم ایمنی بدن کمک می‌کند و برای استفاده در محصولات طبیعی مناسب است.' 
  },
  { 
    id: 6, 
    img: p6, 
    title: 'اسانس کره', 
    description: 'اسانس کره یکی از اسانس‌های پرکاربرد در صنایع غذایی است. این اسانس در صنایع لبنی، به ویژه برای طعم‌دهی به کره‌ها، بستنی‌ها و پنیر پیتزا استفاده می‌شود. اسانس کره در تولید محصولات کیک و شیرینی، به‌ویژه تافی‌ها و انواع شکلات، نیز کاربرد دارد. این اسانس دارای رایحه گرم و شیرین است و در دسرها و نوشیدنی‌ها نیز طعم فوق‌العاده‌ای به ارمغان می‌آورد. اسانس کره با مقاومت حرارتی بالا، گزینه‌ای مناسب برای تولید محصولات با ماندگاری طولانی است.' 
  },
  {
  id: 16,
  img: p16,
  title: 'اسانس فندق',
  description: 'اسانس فندق با طعم غنی و خوشمزه‌اش، یکی از اسانس‌های پرطرفدار در صنعت غذایی است. این اسانس در تولید انواع کیک‌ها، شکلات‌ها، شیرینی‌ها، قهوه و نوشیدنی‌ها کاربرد دارد. اسانس فندق به‌دلیل عطر دلپذیر و طعم بی‌نظیر خود، در دسرها و محصولات شیرینی‌پزی به‌ویژه شکلات‌ها و تافی‌ها بسیار مورد استفاده قرار می‌گیرد. علاوه بر طعم‌دهی، اسانس فندق خواص آنتی‌اکسیدانی دارد و در صنعت آرایشی برای ایجاد رایحه‌های خاص نیز به‌کار می‌رود.'
}
 , { 
    id: 17, 
    img: p17, 
    title: 'اسانس شیر کندانسه', 
    description: 'اسانس شیر کندانسه یکی از اسانس‌های محبوب در صنعت شیرینی‌سازی و تولید محصولات غذایی است. این اسانس طعم شیرین و ملایم شیر کندانسه را به دسرها، کیک‌ها و شکلات‌ها می‌بخشد. علاوه بر استفاده در صنعت غذایی، اسانس شیر کندانسه در تولید محصولات آرایشی نیز کاربرد دارد و رایحه‌ای خوشایند به لوازم بهداشتی می‌بخشد.' 
  },
  { 
    id: 8, 
    img: p8, 
    title: 'اسانس قهوه', 
    description: 'اسانس قهوه طبیعی با طعم تلخ و دلپذیر خود، یکی از محبوب‌ترین اسانس‌ها در صنعت نوشیدنی و دسر است. این اسانس در تولید قهوه، کیک‌ها، شیرینی‌جات و شکلات‌ها به کار می‌رود و طعمی فوق‌العاده به محصولات می‌دهد. اسانس قهوه همچنین در محصولات آرایشی و بهداشتی نیز استفاده می‌شود و عطر خوشبویی به لوسیون‌ها و کرم‌ها می‌بخشد.' 
  },
  { 
    id: 9, 
    img: p9, 
    title: 'اسانس شکلات', 
    description: 'اسانس شکلات یکی از اسانس‌های پرطرفدار در صنایع غذایی است که در تولید انواع کیک‌ها، شکلات‌ها، شیرینی‌جات و دسرها کاربرد دارد. این اسانس با طعم غنی و دلپذیر خود، به محصولات طعم و عطر عالی می‌دهد. اسانس شکلات در صنایع آرایشی و بهداشتی نیز برای تولید لوازم بهداشتی با رایحه شیرین و جذاب مورد استفاده قرار می‌گیرد.' 
  },
  { 
    id: 10, 
    img: p10, 
    title: 'اسانس وانیل', 
    description: 'اسانس وانیل یکی از پرکاربردترین اسانس‌ها در صنایع غذایی و آرایشی است. این اسانس در تولید انواع دسرها، کیک‌ها، نوشیدنی‌ها و شکلات‌ها استفاده می‌شود. اسانس وانیل به‌ویژه در تولید محصولات آرایشی مانند عطرها و کرم‌ها نیز کاربرد دارد. با رایحه خوشبوی و شیرین خود، اسانس وانیل طعم و عطر طبیعی و دلپذیری به محصولات می‌دهد.' 
  },
   {
  id: 11,
  img: p11,
  title: 'اسانس نارگیل',
  description: 'اسانس نارگیل با طعم و عطر گرم و شیرین خود، یکی از محبوب‌ترین اسانس‌ها در صنعت غذایی و نوشیدنی است. این اسانس به‌طور گسترده در تولید انواع کیک‌ها، شیرینی‌ها، دسرها و نوشیدنی‌ها استفاده می‌شود. علاوه بر طعم‌دهی فوق‌العاده، اسانس نارگیل خواص مرطوب‌کننده و ضدالتهابی دارد که در محصولات آرایشی و بهداشتی نیز کاربرد دارد. این اسانس برای کسانی که به دنبال طعمی طبیعی و استوایی در محصولات خود هستند، گزینه‌ای عالی محسوب می‌شود.'
}
 , { 
    id: 12, 
    img: p12, 
    title: 'اسانس توت فرنگی', 
    description: 'اسانس توت فرنگی با طعم شیرین و عطر دلپذیر خود، یکی از پرطرفدارترین اسانس‌ها در صنعت شیرینی‌سازی و دسرها است. این اسانس در تولید کیک‌ها، مرباها، نوشیدنی‌ها و بستنی‌ها کاربرد دارد. علاوه بر این، اسانس توت فرنگی دارای خواص ضد التهابی است و در محصولات آرایشی بهداشتی نیز به کار می‌رود.' 
  },
  { 
    id: 13, 
    img: p13, 
    title: 'اسانس شاه توت', 
    description: 'اسانس شاه توت با طعم منحصر به‌فرد و عطر دلپذیر خود، در تولید انواع نوشیدنی‌ها، دسرها، کیک‌ها و مرباها استفاده می‌شود. این اسانس علاوه بر طعم‌دهی، خواص آنتی‌اکسیدانی و ضد میکروبی دارد و در تولید محصولات آرایشی نیز به‌کار می‌رود.' 
  },
  { 
    id: 15, 
    img: p15, 
    title: 'اسانس انبه', 
    description: 'اسانس انبه طبیعی با طعم شیرین و آبدار خود، در تولید انواع نوشیدنی‌ها، دسرها، بستنی‌ها و کیک‌ها کاربرد دارد. این اسانس طعمی شگفت‌انگیز و رایحه‌ای گرمسیری به محصولات می‌دهد. اسانس انبه علاوه بر صنعت غذایی، در تولید محصولات آرایشی و بهداشتی نیز به‌کار می‌رود.' 
  }
  ,{
  id: 18,
  img: p18,
  title: 'اسانس چای سبز',
  description: 'اسانس چای سبز با رایحه طبیعی و ملایم خود، یکی از اسانس‌های محبوب در صنعت نوشیدنی و دسر است. این اسانس علاوه بر افزودن طعم دلپذیر به چای، دمنوش‌ها، کیک‌ها و شکلات‌ها، خواص ضدoxidant و آرامش‌بخش دارد. اسانس چای سبز به‌دلیل ویژگی‌های سلامتی خود، در محصولات آرایشی نیز کاربرد دارد و در کرم‌ها و لوسیون‌ها به‌عنوان یک ترکیب تسکین‌دهنده و ضد التهاب استفاده می‌شود. این اسانس به تولید محصولاتی با خواص آرامش‌بخش و انرژی‌زا در صنایع مختلف کمک می‌کند.'
}
,{
  id: 19,
  img: p19,
  title: 'اسانس آلوئه ورا',
  description: 'اسانس آلوئه ورا با خواص درمانی و آرامش‌بخش خود، یکی از محبوب‌ترین اسانس‌ها در صنعت محصولات بهداشتی و آرایشی است. این اسانس به دلیل ویژگی‌های مرطوب‌کنندگی و ضد التهابی، در تولید کرم‌ها، لوسیون‌ها و ژل‌های شستشو به کار می‌رود. علاوه بر کاربردهای بهداشتی، اسانس آلوئه ورا در صنایع غذایی نیز استفاده می‌شود، به‌ویژه در نوشیدنی‌ها و دسرها به دلیل طعم طبیعی و ملایم آن. این اسانس کمک به تسکین پوست و تقویت سیستم ایمنی بدن می‌کند.'
}
,{
  id: 20,
  img: p20,
  title: 'اسانس زیتون',
  description: 'اسانس زیتون با عطر و طعم طبیعی و خواص بی‌نظیر خود، در صنایع غذایی و آرایشی کاربرد گسترده‌ای دارد. در صنایع غذایی، این اسانس برای طعم‌دهی به انواع روغن‌ها، نان‌ها، سس‌ها و محصولات مدیترانه‌ای استفاده می‌شود. همچنین، به دلیل خواص آنتی‌اکسیدانی و نرم‌کنندگی، در تولید محصولات مراقبتی پوست و مو، مانند کرم‌ها و شامپوها، بسیار مورد توجه است. اسانس زیتون با رایحه‌ای ملایم و دلنشین، کیفیت محصولات را بهبود می‌بخشد و به‌عنوان یک گزینه طبیعی و سالم در تولیدات مختلف شناخته می‌شود.'
}
,{
  id: 21,
  img: p21,
  title: 'اسانس شیر موز',
  description: 'اسانس شیر موز با ترکیبی از طعم لطیف موز و بافت کرمی شیر، یکی از محبوب‌ترین اسانس‌ها در صنایع غذایی است. این اسانس برای تولید انواع نوشیدنی‌های آماده، بستنی‌ها، دسرها و کیک‌ها به‌کار می‌رود. رایحه گرم و طبیعی شیر موز تجربه‌ای خوشایند برای مصرف‌کننده ایجاد می‌کند و طعمی جذاب به محصولات می‌بخشد. اسانس شیر موز علاوه بر طعم‌دهی، به ایجاد حس تازه و انرژی‌بخش در محصولات کمک می‌کند و گزینه‌ای ایده‌آل برای تولیدات خلاقانه محسوب می‌شود.'
}
,{
  id: 22,
  img: p22,
  title: 'اسانس لیمو ترش',
  description: 'اسانس لیمو ترش با عطر تازه و طعم تند و دلپذیر، یکی از پرکاربردترین اسانس‌ها در صنایع غذایی و آرایشی است. این اسانس در تولید نوشیدنی‌های گازدار و بدون گاز، کیک‌ها، دسرها و آب‌نبات‌ها به‌کار می‌رود. خاصیت طراوت‌بخشی و خنک‌کنندگی لیمو ترش، محصولات را متمایز می‌سازد و حس تازگی و شادابی به مصرف‌کننده القا می‌کند. اسانس لیمو ترش همچنین به دلیل دارا بودن خواص آنتی‌اکسیدانی و ضد باکتریایی، در محصولات آرایشی و بهداشتی نیز استفاده می‌شود.'
}


];



export function Products  ()  {
      const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]); 
    return (
        <div className="products-container">
            <h2>محصولات ما</h2>
            <div className="products-grid">
                {products.map((product) => (
                    <div className="product-card" key={product.id}>
                        <img src={product.img} alt={product.title} />
                        <h3>{product.title}</h3>
                        <p>{product.description}</p>
                        <Link to={`/mahsulat/${product.id}`}>
                            <button className='view-all-button '  onClick={() => { 

    window.scrollTo({ top: 0, behavior: 'smooth' }); // برای اسکرول کردن به بالای صفحه
  }}>جزئیات محصول</button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Products;
