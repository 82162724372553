import React from 'react';
import { Link } from 'react-router-dom';
import y1 from "../../assets/america.jfif";
import y2 from "../../assets/logo1.png";
import cato from "../../assets/catalog.pdf";
import { useTheme } from '../ThemeContext/ThemeContext'; // وارد کردن useTheme
import './Navebar.css';

const Navbar = () => {
  const { isDarkMode, toggleDarkMode } = useTheme(); // استفاده از useTheme

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  };

  return (
    <nav className={`navbar ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="logo"><img src={y2} alt="Logo" /></div>
      <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
        <li id="nav-links" onClick={() => { closeMenu(); scrollToTop(); }}>
          <lord-icon src="https://cdn.lordicon.com/epietrpn.json" trigger="loop"></lord-icon>
          <Link to="/">خانه</Link>
        </li>
        <li id="nav-links" onClick={() => { closeMenu(); scrollToTop(); }}>
          <lord-icon src="https://cdn.lordicon.com/dvqeipeg.json" trigger="loop" delay="2000"></lord-icon>
          <Link to="/services">خدمات</Link>
        </li>
        <li id="nav-links" onClick={() => { closeMenu(); scrollToTop(); }}>
          <lord-icon src="https://cdn.lordicon.com/yqmzxvos.json" trigger="loop"></lord-icon>
          <Link to="/mahsulat">انواع اسانس</Link>
        </li>
        <li id="nav-links" onClick={() => { closeMenu(); scrollToTop(); }}>
          <lord-icon src="https://cdn.lordicon.com/veoexymv.json" trigger="loop" delay="2000"></lord-icon>
          <a href={cato} download="catalog.pdf" className="download-button">
            دانلود کاتالوگ
          </a>
        </li>
        <li id="nav-links" onClick={() => { closeMenu(); scrollToTop(); }}>
          <lord-icon src="https://cdn.lordicon.com/okdadkfx.json" trigger="loop"></lord-icon>
          <Link to="/representatives">نمایندگی‌ها</Link>
        </li>
        <li id="nav-links" onClick={() => { closeMenu(); scrollToTop(); }}>
          <lord-icon src="https://cdn.lordicon.com/kiynvdns.json" trigger="loop" delay="2000"></lord-icon>
          <Link to="/contact" className="contact-button">تماس با ما</Link>
        </li>
      </ul>
      <div className="lang-container">
        <img src={y1} alt="English Flag" className="flag-icon" />
        <Link to="/english">
          <button className="lang-button">English</button>
        </Link>
      </div>
      <button className="dark-mode-toggle" onClick={toggleDarkMode}>
        {isDarkMode ? '🌙' : '🌞'}
      </button>
    </nav>
  );
};

export default Navbar;
